import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ProductCardComponent extends Component {
  @service router;
  @service cart;
  @service favorites;
  @service session;
  @service intl;
  @service product;
  @service file;

  @tracked cartCount;
  @tracked countInCart = 0;

  constructor() {
    super(...arguments);

    this.getCountInCart();
    this.file.getNoImages();
  }

  get favorite() {
    const product = this.args.product;

    if (this.args.product) {
      return this.favorites.getFavoriteProduct(product);
    }

    return null;
  }

  get favInfo() {
    if (this.session.isAuthenticated) {
      if (this.favorite) {
        return this.intl.t('favorite.userHelpInfo.remove');
      }

      return this.intl.t('favorite.userHelpInfo.add');
    }

    return this.intl.t('favorite.guessHelpInfo');
  }

  get getProductData() {
    return this.product.getProductData(this.args.product);
  }

  get priceWtax() {
    return parseFloat(this.args.product.price * 1.2).toFixed(2);
  }

  @action
  closePopup(){
    if (typeof this.args.closePopup !== undefined) { this.args.closePopup(); }
  }

  @action
  async addToCart() {
    console.log(`Start add to cart count: ${this.cartCount}`)
    if (this.cartCount <= 0) return;
    console.log(`this.cartCount <= 0`)
    await this.cart.checkCart();
    console.log(`this.cart.checkCart()`)
    await this.cart.addItem(this.args.product, this.cartCount);
    console.log(`await this.cart.addItem(this.args.product, this.cartCount);`)
    this.cartCount = 0;
    console.log(`this.cartCount = 0;`)
    this.getCountInCart();
    console.log(`this.getCountInCart();`)
  }

  @action
  minusCartCount() {
    let cartCount = this.cartCount || 0;

    if (cartCount === 1) return;

    this.cartCount--;
  }

  @action
  plusCartCount() {
    if (!this.cartCount) this.cartCount = 0;
    this.cartCount++;
  }

  @action
  async removeFromCart() {
    await this.cart.removeItem(this.args.product, true);
    this.getCountInCart();
  }

  @action
  async getCountInCart() {

    if (!this.cart.getCartItem(this.args.product)) {
      this.countInCart = 0;
      return;
    }
    this.countInCart = this.cart.getCartItem(this.args.product).quantity;
  }

  @action
  async removeFavorite() {
    await this.favorites.removeFavoriteProduct(this.args.product);
  }

  @action
  async addFavorite() {
    await this.favorites.addFavoriteProduct(this.args.product);
  }
}
