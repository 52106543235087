import Route from '@ember/routing/route';
import {
  inject as service,
} from '@ember/service';
import {
  hash,
} from 'rsvp';

export default class FileIndexRoute extends Route {
  @service intl;

  queryParams = {
    // genaral
    page: {
      refreshModel: true,
    },
    size: {
      refreshModel: true,
    },
  };

  constructor() {
    super(...arguments);
  }

  afterModel() {
    this.setHeadTags();
  }

  setHeadTags() {
    let headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.intl.t('common.desc'),
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: this.intl.t('common.keywords'),
        },
      },
    ];

    this.set('headTags', headTags);
  }

  model() {
    return hash({
      content: this.store.query('text-block', {
        filter: {
          title: ['file', 'novosti'],
        },
        sort: 'id',
        page: {
          number: 1,
          size: 2,
        },
      }),
      groups: this.store.query('download-group', {
        sort: 'id',
      }),
    });
  }
}
