import Service, {
  inject as service,
} from '@ember/service';
import {
  computed,
} from '@ember/object';
import axios from 'axios';

import ENV from 'bumgild-app-client/config/environment';

export default class AxiosService extends Service {
  @service session

  baseUrl = ENV.API.host;

  @computed('session.data.authenticated.access_token')
  get headers() {
    const headers = {};
    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }

  constructor() {
    super(...arguments);

    this.axios = axios.create({
      baseURL: this.baseUrl,
      headers: this.headers,
    });

  }

  request(options) {
    return this.axios.get(options);
  }

  get(options) {
    return this.axios.get(options);
  }

  post(url, data) {
    return this.axios.post(url, data);
  }

  // delete = this.axios.delete;
  // head = this.axios.head;
  // options = this.axios.options;
  // put = this.axios.put;
  // patch = this.axios.patch;
}
