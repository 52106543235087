import Component from '@glimmer/component';
import {
  action,
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import {
  tracked,
} from '@glimmer/tracking';

export default class StockProductCardComponent extends Component {
  @service router;
  @service cart;
  @service favorites;
  @service session;
  @service intl;
  @service product;
  @service file;

  @tracked cartCount;
  @tracked countInCart = 0;

  constructor() {
    super(...arguments);

    this.getCountInCart();
    this.file.getNoImages();
  }

  @computed('args.product')
  get getProductData() {
    return this.product.getProductData(this.args.product);
  }

  get priceWtax() {
    return parseFloat(this.args.product.price * 1.2).toFixed(2);
  }

  @action
  async addToCart() {
    if (this.cartCount <= 0) return;
    await this.cart.checkCart();
    await this.cart.addItem(this.args.product, this.cartCount);
    this.cartCount = 0;
    this.getCountInCart();
  }

  @action
  minusCartCount() {
    let cartCount = this.cartCount || 0;

    if (cartCount === 1) return;

    this.cartCount--;
  }

  @action
  plusCartCount() {
    if (!this.cartCount) this.cartCount = 0;
    this.cartCount++;
  }

  @action
  async removeFromCart() {
    await this.cart.removeItem(this.args.product, true);
    this.getCountInCart();
  }

  @action
  async getCountInCart() {
    await this.cart.getCart();

    if (!this.cart.getCartItem(this.args.product)) {
      this.countInCart = 0;
      return;
    }
    this.countInCart = this.cart.getCartItem(this.args.product).quantity;
  }
}
