import Model, {
  attr,
  hasMany,
} from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class SubCategoryModel extends Model {
  @attr('string') desc;
  @attr('string') image;
  @attr('string') name;
  @attr('string') slug;
  @attr('string') keywords;
  @attr('string') seoTitle;
  @attr('string') seoDescription;
  @attr('string') seoH1;
  @attr('string') seoH2;
  @attr('boolean') hidden;

  @hasMany('filter') filters;
  @hasMany('product') products;
  get getDesc() {
    htmlSafe(this.desc)
  }
}
