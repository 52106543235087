import Route from '@ember/routing/route';
import {
  hash,
} from 'rsvp';
import {
  inject as service,
} from '@ember/service';

export default class BlogRoute extends Route {
  @service intl;

  queryParams = {
    // genaral
    page: {
      refreshModel: true,
    },
    size: {
      refreshModel: true,
    },
  };

  async model(params) {
    const page = params.page === undefined ? 1 : params.page;
    let content = await this.store.query('text-block', {
      filter: {
        title: 'blog',
      },
      sort: '-date',
      page: {
        number: page,
        size: params.size === undefined ? 10 : params.size,
      },
    });
    let pagination = [];
    for (let i = 1; i <= content.meta.page_count; i++) { pagination.push(i); }
    if (pagination.length < 2) { pagination = []; }

    return {
      content: content.toArray().sortBy('date'),
      pagination,
      page
    };
  }

  afterModel(resolvedModel) {
    this.setHeadTags();
  }


  setHeadTags() {
    let headTags = [
      {
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.intl.t('blog.desc'),
        },
      },
    ];

    this.set('headTags', headTags);
  }

}
