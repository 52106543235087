import Component from '@glimmer/component';
import { action, computed, notifyPropertyChange } from '@ember/object';
import { sort } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class PersonalHystoryComponent extends Component {
  @service favorites;

  perPage = 10;
  @tracked page = 1;
  @tracked pagedOrders = [];
  @tracked pages = [];

  ordersSortingDesc = ['createdAt:desc'];
  @sort('args.orders', 'ordersSortingDesc') sortedOrders;

  constructor() {
    super(...arguments);
    // this.favorites.getFavorites();
  }

  @computed('sortedOrders')
  get initOrders() {
    this.cropOrders();

    return true;
  }

  @action
  changePage(page) {
    if (!page) return;

    this.page = page;
    this.cropOrders();
  }

  @action
  cropOrders() {
    let orders = [];
    let i = 0;
    let endPoint = this.page * this.perPage;
    let startPoint = endPoint - this.perPage;

    if (this.sortedOrders.length > this.perPage) {
      for (let p = 1; p <= Math.ceil(this.sortedOrders.length / this.perPage); p++) {
        this.pages.push(p);
      }

      this.sortedOrders.forEach(order => {
        if (i >= startPoint && i < endPoint) {
          orders.push(order);
        }
        i++;
      })
    } else {
      orders = this.sortedOrders;
    }
    this.pagedOrders = orders;

    notifyPropertyChange(this, 'pagedOrders');
  }
}
