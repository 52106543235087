import Service from '@ember/service';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';
import {
  resolve,
} from 'rsvp';

export default class CurrentUserService extends Service {
  @service session;
  @service store;


  @tracked user;

  constructor() {
    super(...arguments);
  }
  async load() {
    if (this.session.isAuthenticated) {
      try {
        this.user = await this.store.queryRecord('user', {
          me: true,
        });

        // await this.connect();

        // this.user.set('availability', 'online');
        // this.user.save();

      } catch (error) {
        // TODO: report error to sentry
      }
    } else {
      return resolve();
    }
  }
}
