import Model, {
  attr,
} from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class TextBlockModel extends Model {

  @attr('string') title;
  @attr('string') body;
  @attr('string') date;
  @attr('string') text_h1;
  @attr('string') text_name;
  @attr('string') text_title;
  @attr('string') text_description;
  @attr() image1;
  @attr() image2;
  @attr() image3;
  @attr('string') file_name1;
  @attr('string') file_name2;
  @attr('string') file_name3;
  
  get getBody() {
    return htmlSafe(this.body.replaceAll('Verdana;', 'Inter, sans-serif;'))    
  }
  
  get getImage1() {
    return this.image1?.url
  }
  get getImage2() {
    return this.image2?.url
  }
  get getImage3() {
    return this.image3?.url
  }

  get imgName1() {
    return this.file_name1
  }

  get imgName2() {
    return this.file_name2
  }

  get imgName3() {
    return this.file_name3
  }

}
