import Model, {
  attr,
  belongsTo,
} from '@ember-data/model';
import {
  alias,
} from '@ember/object/computed';
import { htmlSafe } from '@ember/template';

export default class OrderFileModel extends Model {
  @attr('string') file;
  @attr() desc;
  @attr('date') createdAt;
  @belongsTo('order') order;

  @alias('desc.url') fileUrl;

  get getDesc() {
    htmlSafe(this.desc)
  }
}
