import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CartService extends Service {
  @service store;
  @service('fake-fastboot') fastboot;
  @service intl;
  @service product;

  @tracked cartId;
  @tracked eventMessage;
  @tracked totalSum = 0;
  @tracked cartData;

  constructor() {
    super(...arguments);
    this.messageTimer = null;
  }

  async checkCart() {
    if (!this.fastboot.isFastBoot) {
      this.cartId = localStorage.getItem('cartId');

      if (this.cartId) {
        await this.store
          .findRecord('cart', this.cartId, {
            include: 'cart_items',
          })
          .then((cartData) => {
            this.cartData = cartData;
            this.getTotals();
          })
          .catch(async () => {
            await this.createEmptyCart();
          });
      } else {
        await this.createEmptyCart();
      }
    }
  }

  async getCart() {
    if (!this.fastboot.isFastBoot) {
      this.cartId = localStorage.getItem('cartId');

      if (this.cartId) {
        await this.store
          .findRecord('cart', this.cartId, {
            include: 'cart_items',
          })
          .then((cartData) => {
            this.cartData = cartData;
            this.getTotals();
          })
          .catch(() => {
            localStorage.setItem('cartId', '');
          });
      } else {
        // this.cartData = null;
      }
    }
  }

  async createEmptyCart() {
    const cart = this.store.createRecord('cart');
    await cart.save().then((savedSelection) => {
      this.cartData = savedSelection;
      localStorage.setItem('cartId', savedSelection.get('id'));
    });
  }

  async addItem(product, count = 1) {
    if (!product) return false;

    this.clearMessageTimeout();

    if (this.plusItem(product, count)) {
      return true;
    } else {
      const productData = this.product.getProductData(product);
      const newCartItem = this.store.createRecord('cart_item', {
        product: product,
        quantity: parseFloat(count),
        price: productData.price,
        cart: this.cartData,
      });

      return await newCartItem
        .save()
        .then(async () => {
          this.eventMessage = this.intl.t('cart.item.added');
          this.clearMessage();
          await this.getCart();
        })
        .catch(() => {
          this.eventMessage = this.intl.t('cart.item.error');
          this.clearMessage();
        });
    }
  }

  updateQuantity(product, quantity) {
    const cartItem = this.getCartItem(product);

    if (!cartItem) return false;

    this.clearMessageTimeout();

    return this.store
      .findRecord('cart-item', cartItem.get('id'))
      .then((item) => {
        item.set('quantity', parseFloat(quantity));
        return item
          .save()
          .then(() => {
            this.eventMessage = this.intl.t('cart.item.quantityUpdated');
            this.clearMessage();
            this.getCart();
          })
          .catch(() => {
            return false;
          });
      })
      .catch(() => {
        return false;
      });
  }

  plusItem(product, count = 1) {
    const cartItem = this.getCartItem(product);
    if (!cartItem) return false;

    return this.updateQuantity(
      product,
      parseFloat(cartItem.quantity) + parseFloat(count),
    );
  }

  minusItem(product) {
    const cartItem = this.getCartItem(product);

    if (!cartItem) return false;

    this.clearMessageTimeout();

    if (cartItem.quantity === 1) {
      return this.removeItem(product);
    } else {
      return this.updateQuantity(product, --cartItem.quantity);
    }
  }

  async deleteItem(item) {
    await this.store
      .findRecord('cart-item', item.get('id'), {
        backgroundReload: false,
      })
      .then(async (data) => {
        await data.destroyRecord();
      });
  }

  async removeItem(product) {
    const cartItem = this.getCartItem(product);
    if (!cartItem) return false;

    this.clearMessageTimeout();

    return this.store
      .findRecord('cart-item', cartItem.get('id'), {
        backgroundReload: false,
      })
      .then((item) => {
        return item
          .destroyRecord()
          .then(() => {
            this.eventMessage = this.intl.t('cart.item.removed');
            this.clearMessage();
            this.getCart();
          })
          .catch(() => {
            return false;
          });
      });
  }

  getCartItem(product) {
    let id = product.get('id');

    if (!id) {
      id = product.id;
    }

    if (!this.cartData || !id) return;
    return this.cartData
      .get('cartItems')
      .find((item) => item.get('product').get('id') === id);
  }

  clearMessage() {
    this.messageTimer = setTimeout(() => {
      this.eventMessage = null;
    }, 3000);
  }

  clearMessageTimeout() {
    this.eventMessage = null;
    clearTimeout(this.messageTimer);
  }

  getTotals() {
    if (!this.cartData) return;

    this.cartData.get('cartItems').then((model) => {
      this.totalSum = 0;
      model.forEach((item) => {
        item.total = (item.price * item.quantity * 1.2).toFixed(2);
        this.totalSum = this.totalSum + parseFloat(item.total);
        // item.price = item.price.toFixed(2);
      });

      this.totalSum = this.totalSum.toFixed(2);
    });
  }
}
