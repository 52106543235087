import Model, {
  attr,
  hasMany,
} from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class RubricModel extends Model {
  @attr('string') desc;
  @attr('string') image;
  @attr('string') name;
  @attr('string') slug;
  @attr('number') order;
  @attr('string') keywords;
  @attr('string') seoTitle;
  @attr('string') seoDescription;
  @attr('string') seoH1;
  @attr('string') seoH2;

  @hasMany('product') products;
  @hasMany('category') categories;
  get getDesc() {
    
    return htmlSafe(this.desc)
    
  }
}
