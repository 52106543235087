import { underscore } from '@ember/string';
import JSONAPISerializer from '@ember-data/serializer/json-api';

export default class MainSerializer extends JSONAPISerializer {
  keyForAttribute(key) {
    return underscore(key);
  }

  keyForRelationship(key) {
    return underscore(key);
  }
}
