import Route from '@ember/routing/route';
import {
  hash,
} from 'rsvp';
import {
  inject as service,
} from '@ember/service';

export default class CatalogRubricCategoryProductsRoute extends Route {
  constructor() {
    super(...arguments);
  }

  @service intl;
  @service util;

  afterModel(model) {
    if(!model){
      this.transitionTo('/not-found');
      return;
    }

    this.setHeadTags(model);
  }

  setHeadTags(model) {
    const description = model.product.firstObject.seoDescription;
    const headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.util.truncate(description, 170, true),
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: model.product.firstObject.keywords,
        },
      },
    ];

    this.set('headTags', headTags);
  }

  async model({
    product_slug,
  }) {

    const rubric = this.modelFor('catalog.rubric');
    const {
      category,
    } = this.modelFor('catalog.rubric.category');

    const product = await this.store.query('product', {
      filter: {
        slug: product_slug,
      },
      include: 'product_attributes,category',
      sort: 'id',
      page: {
        number: 1,
        size: 1,
      },
    });

    if(product.get('length') === 0) {
      return null;
    }

    return hash({
      category,
      rubric,
      product,
    });
  }
}
