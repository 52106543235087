import Service from '@ember/service';
import {
  inject as service,
} from '@ember/service';
import {
  tracked,
} from '@glimmer/tracking';
import { computed } from '@ember/object';

export default class FavoritesService extends Service {
  @service store;
  @service currentUser;
  @service intl;

  // @tracked isFavoritesDone = false;
  @tracked eventMessage;
  @tracked favoriteData = [];
  @tracked favoriteProducts = [];

  constructor() {
    super(...arguments);
    this.messageTimer = null;
    this.fetchFavorites();
  }

  async fetchFavorites() {
    if (this.currentUser.user) {
      this.favoriteData = await this.store.query('favorit-product', {
          'filter[user]': this.currentUser.user.get('id'),
      });
      this.favoriteProducts = this.favoriteData.mapBy('product.content');
    }
  }

  // getFavorites() {
  //   // console.log('getFavorites')
  //   return this.favoriteData;
  // }

  getFavoriteProduct(product) {
    if (!this.favoriteData) return;

    return this.favoriteData.find(
      item => item.get('product').get('id') === product.id,
    );
  }

  async addFavoriteProduct(product) {
    if (!product || !this.currentUser.user) return false;

    this.clearMessageTimeout();

    const newFavoriteItem = this.store.createRecord('favorit-product', {
      user: this.currentUser.user,
      product,
    });

    return await newFavoriteItem
      .save()
      .then(async () => {
        this.eventMessage = this.intl.t('favorite.product.added');
        this.clearMessage();
        // await this.getFavorites();
        await this.fetchFavorites();
      })
      .catch(() => {
        this.eventMessage = this.intl.t('favorite.product.error');
        this.clearMessage();
      });
  }

  async removeFavoriteProduct(product) {
    const favoriteProduct = this.getFavoriteProduct(product);
    if (!favoriteProduct) return false;

    return await this.store
      .findRecord('favorit-product', favoriteProduct.get('id'), {
        backgroundReload: false,
      })
      .then(item => {
        return item
          .destroyRecord()
          .then(async () => {
            this.eventMessage = this.intl.t('favorite.product.removed');
            this.clearMessage();
            // this.getFavorites();
            await this.fetchFavorites();
          })
          .catch(() => {
            return false;
          });
      });
  }

  clearMessage() {
    this.messageTimer = setTimeout(() => {
      this.eventMessage = null;
    }, 3000);
  }

  clearMessageTimeout() {
    this.eventMessage = null;
    clearTimeout(this.messageTimer);
  }
}
