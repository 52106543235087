import Model, {
  attr,
  hasMany,
} from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class DownloadGroupModel extends Model {
  @attr('string') title;
  @attr('string') desc;
  @attr('string') keywords;

  @hasMany('download') downloads;
  get getDesc() {
    htmlSafe(this.desc)
  }
}
