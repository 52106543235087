import Route from '@ember/routing/route';
import {
  action,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import {
  hash,
} from 'rsvp';

export default class ExpressRoute extends Route {
  @service intl;
  @service currentUser;
  @service('fake-fastboot') fastboot;

  constructor() {
    super(...arguments);
  }

  @action
  willTransition() {
    this.controllerFor('express').comment = null;
  }

  @action
  didTransition() {
    if (!this.fastboot.isFastBoot) {
      this.controllerFor('express').comment = localStorage.getItem('expressOrderText');
    }
  }

  afterModel() {
    this.setHeadTags();
  }

  setHeadTags() {
    let headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.intl.t('common.desc'),
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: this.intl.t('common.keywords'),
        },
      },
    ];

    this.set('headTags', headTags);
  }

  model() {
    return hash({
      content: this.store.query('text-block', {
        filter: {
          title: this.routeName,
        },
        sort: 'id',
        page: {
          number: 1,
          size: 1,
        },
      }),
      user: this.currentUser.user ?
        this.store.findRecord('user', this.currentUser.user.id, {
          include: 'account,user_contacts,delivery_addresses',
        }) : null,
    })
  }
}
