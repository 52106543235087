import Service from '@ember/service';
import {tracked} from '@glimmer/tracking'
import  {
    inject as service,
  } from '@ember/service';
export default class FakeFastbootService extends Service {
    //  @service('fastboot') fastboot

      get isFastBoot() {
     
          return false
      }
}
