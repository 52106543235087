import Component from '@glimmer/component';
import {
  task,
} from 'ember-concurrency-decorators';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';
import {
  action,
  computed,
} from '@ember/object';

export default class CartItemsListComponent extends Component {
  @service store;
  @service order;
  @service router;
  @service currentUser;
  @service('fake-fastboot') fastboot;

  @tracked comment;
  @tracked userContact;
  @tracked deliveryAddress;
  @tracked submited;

  @computed('currentUser')
  get user() {
    return this.currentUser.user ?
      this.store.findRecord('user', this.currentUser.user.id, {
        include: 'account,user_contacts,delivery_addresses',
      }) :
      null;
  }

  @action
  selectOption(target) {
    this[target.name] = target.value;
  }

  @task
  makeOrder = function* (event) {
    event.preventDefault();

    yield this.createOrder();
  }

  @action
  auto_grow(element) {
    element.style.height = '5px';
    element.style.height = (element.scrollHeight) + 'px';
  }

  async createOrder() {

    this.submited = true;

    this.order.createOrder(this.userContact, this.deliveryAddress, this.comment, true);
  }
}
