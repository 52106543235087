import Route from '@ember/routing/route';
import {
  inject as service,
} from '@ember/service';

export default class CatalogRoute extends Route {
  @service('fake-fastboot') fastboot;

  afterModel() {
    super.afterModel(...arguments);

    // if (!this.fastboot.isFastBoot) {
    //   const main = document.getElementById("mainContent");
    //   const breadcrumbs = document.getElementById("breadcrumbs");

    //   if (main) {
    //     console.log(
    //       main.getBoundingClientRect().top +
    //         window.scrollY -
    //         breadcrumbs.offsetHeight
    //     );
    //     console.log(breadcrumbs.offsetHeight);
    //     const topPos =
    //       main.getBoundingClientRect().top +
    //       window.scrollY -
    //       breadcrumbs.offsetHeight -
    //       140;

    //     window.scrollTo(0, topPos);
    //     setTimeout(function() {
    //       window.scrollTo(0, topPos);
    //     }, 1000);
    //   }
    // }
  }
}
