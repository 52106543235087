import Route from '@ember/routing/route';
import {
  computed,
} from '@ember/object';
import {
  hash,
} from 'rsvp';

export default class CatalogRubricCategorySubCategoryRoute extends Route {
  @computed('controller.model.subCategory.name')
  get breadCrumb() {
    return {
      title: this.controller.model.subCategory.name,
    };
  }

  afterModel(model) {
    if(!model){
      this.transitionTo('/not-found');
      return;
    }
  }

  async model({
    sub_category_slug,
  }) {
    const {
      category,
    } = this.modelFor('catalog.rubric.category');

    const subCategory = await this.store.query('sub-category', {
      filter: {
        slug: sub_category_slug,
      },
      sort: 'id',
      page: {
        number: 1,
        size: 1,
      },
    });

    if(subCategory.get('length') === 0) {
      return null;
    }

    return hash({
      category,
      subCategory,
      filters: this.store.query('filter', {
        sub_category_slug,
      }),
    });
  }
}
