import ApplicationAdapter from './application';

export default class orderAdapter extends ApplicationAdapter {
  createRecord(store, type, snapshot) {
    const url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
    const data = this.serialize(snapshot, { includeId: true });
    for (const model in data.data.relationships) {
      if (!data.data.relationships.hasOwnProperty(model) || !data.data.relationships[model].data) {
        continue;
      }

      data.data.relationships[model].data.type = data.data.relationships[model].data.type.replace(/-/g, '_');
    }

    return this.ajax(url, 'POST', { data });
  }

  updateRecord(store, type, snapshot) {
    const url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
    const data = this.serialize(snapshot, { includeId: true });
    for (const model in data.data.relationships) {
      if (!data.data.relationships.hasOwnProperty(model) || !data.data.relationships[model].data) {
        continue;
      }

      data.data.relationships[model].data.type = data.data.relationships[model].data.type.replace(/-/g, '_');
    }

    return this.ajax(url, 'PUT', { data });
  }
}
