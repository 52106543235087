function spritUnnecessaryTags(html) {
  if (!html || !html.includes('<body>')) {
    return html;
  }

  return html.match(/<body>(.*)<\/body>/s)[1];
}

function strippedString(html) {
  return html.replace(/(<([^>]+)>)/gi, '');
}
export {
  spritUnnecessaryTags,
  strippedString,
}
