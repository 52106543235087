import Controller from '@ember/controller';
import {
  action,
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import {
  tracked,
} from '@glimmer/tracking';
import ENV from '../../config/environment';

export default class PersonalController extends Controller {
  @service router;
  @service intl;
  @service session;
  @service currentUser;
  @service('fake-fastboot') fastboot;
  @tracked curTab = 'data';

  apiHost = ENV.API.host;

  constructor() {
    super(...arguments);
    this.currentUser.load();
  }

  tabs = [{
      id: 'data',
      name: this.intl.t('personal.tabs.data'),
    },
    {
      id: 'users',
      name: this.intl.t('personal.tabs.users'),
    },
    {
      id: 'addresses',
      name: this.intl.t('personal.tabs.addresses'),
    },
    {
      id: 'hystory',
      name: this.intl.t('personal.tabs.hystory'),
    },
  ];

  @computed('intl')
  get breadCrumbs() {
    return [{
        label: this.intl.t('common.home'),
        path: 'home',
      },
      {
        label: this.intl.t('personal.title'),
        isCurrent: true,
        linkable: false,
      },
    ];
  }

  @action
  changeTab(tab) {
    this.curTab = tab || this.tabs.firstObject.id;
    return true;
  }
}
