import Component from '@glimmer/component';
import {
  inject as service,
} from '@ember/service';

export default class ProductSliderComponent extends Component {
  @service file;

  constructor() {
    super(...arguments);

    this.file.getNoImages();
  }
}
