import Model, {
  attr,
  belongsTo,
} from '@ember-data/model';

export default class DeliveryAddressModel extends Model {

  @attr('string') address;
  @belongsTo('user') user;
}
