import Controller from '@ember/controller';
import {
  inject as service,
} from '@ember/service';
import {
  tracked,
} from '@glimmer/tracking';
import {
  task,
} from 'ember-concurrency-decorators';

export default class PasswordResetController extends Controller {
  @service axios;
  @service router;
  @service intl;
  @service('fake-fastboot') fastboot;

  @tracked userData = {
    email: !this.fastboot.isFastBoot ? localStorage.getItem('lastMail') : null,
    token: this.router.currentRoute.params.token,
    password: null,
    repeatPassword: null,
  };

  @tracked submited = false;
  @tracked nextMessage;

  @task
  resetPassword = function* (event) {
    event.preventDefault();

    this.submited = true;

    if (
      !this.userData.email ||
      !this.userData.password ||
      !this.userData.repeatPassword
    )
      return;

    if (this.userData.password.length < 6) return;
    if (this.userData.password != this.userData.repeatPassword) return;

    try {
      yield this.axios
        .post('password/reset/', this.userData)
        .then(res => {
          if (res.data.error) {
            this.nextMessage = res.data.error == 'Token not present' ? this.intl.t('registration.passResetFaild') : res.data.error;
          } else {
            this.nextMessage = this.intl.t('registration.passResetSuccess');
          }
        });
    } catch (reason) {
      this.nextMessage = this.intl.t('registration.passResetFaild');
    }
  };
}
