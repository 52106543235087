import Controller from '@ember/controller';
import {
  action,
} from '@ember/object';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';
import {
  task,
} from 'ember-concurrency-decorators';

export default class PasswordRepairController extends Controller {
  @service axios;
  @service('fake-fastboot') fastboot;

  @tracked email;
  @tracked errorMessage;
  @tracked used = false;

  @action
  handleInput(field, event) {
    this[field] = event.target.value.toLowerCase();
  }

  @task
  repair = function* (event) {
    event.preventDefault();

    // eslint-disable-next-line no-useless-escape
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) return;

    this.errorMessage = '';

    yield this.axios
      .post('password/forgot', {
        email: this.email,
      })
      .then(res => {
        if (res.status == 200) {
          this.used = true;
          if (!this.fastboot.isFastBoot)
            localStorage.setItem('lastMail', this.email);
        } else {
          this.errorMessage = res.data.message == 'Email not present' ? this.intl.t('registration.notFound') : res.data.message;
        }
      });
  };
}
