import Component from '@glimmer/component';
import {
  action,
} from '@ember/object';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';

export default class HeaderNavComponent extends Component {
  @service store;

  @tracked burgerActive;

  @action
  toogleMenu() {
    this.burgerActive = this.burgerActive ? '' : 'open';
  }
}
