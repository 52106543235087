import Route from '@ember/routing/route';
import {
  inject as service,
} from '@ember/service';
import {
  isEmpty,
} from '@ember/utils';
import {
  hash,
} from 'rsvp';

export default class CatalogRubricCategorySubCategoryIndexRoute extends Route {
  @service('fake-fastboot') fastboot;
  @service intl;
  @service util;

  queryParams = {
    // genaral
    page: {
      refreshModel: true,
    },
    size: {
      refreshModel: true,
    },
    // filteres
    sortVal: {
      refreshModel: true,
    },
    sortDir: {
      refreshModel: true,
    },
    brand: {
      refreshModel: true,
    },
    weight: {
      refreshModel: true,
    },
    glubinazachvata: {
      refreshModel: true,
    },
    diametr: {
      refreshModel: true,
    },
    vtulka: {
      refreshModel: true,
    },
    dlina: {
      refreshModel: true,
    },
    klass: {
      refreshModel: true,
    },
    linovka: {
      refreshModel: true,
    },
    material: {
      refreshModel: true,
    },
    nasyischennost: {
      refreshModel: true,
    },
    plotnost: {
      refreshModel: true,
    },
    poverhnost: {
      refreshModel: true,
    },
    probivaet_listov: {
      refreshModel: true,
    },
    razmer: {
      refreshModel: true,
    },
    razryad: {
      refreshModel: true,
    },
    seria: {
      refreshModel: true,
    },
    skoba: {
      refreshModel: true,
    },
    sshivaetlistov: {
      refreshModel: true,
    },
    tverdost: {
      refreshModel: true,
    },
    tip: {
      refreshModel: true,
    },
    tolschina: {
      refreshModel: true,
    },
    format: {
      refreshModel: true,
    },
    colour: {
      refreshModel: true,
    },
    shirina: {
      refreshModel: true,
    },
  };

  beforeModel() {
    this.controllerFor('catalog.rubric.category.sub-category.index').set('ready', false)
  }

  afterModel(model) {
    this.controllerFor('catalog.rubric.category.sub-category.index').set('ready', true)
    this.setHeadTags(model);

    super.afterModel(...arguments);

    // if (!this.fastboot.isFastBoot) {
    //   const sFull = document.getElementById('header-sFull');
    //   const sSmall = document.getElementById('header-sSmall');

    //   let h = sFull ? sFull.offsetHeight : 0;
    //   if (h === 0) {
    //     h = sSmall ? sSmall.offsetHeight - 16 : 0;
    //   }

    //   const element = document.getElementById('breadcrumbs');
    //   const topPos =
    //     element.getBoundingClientRect().top + window.scrollY - h - 22;

    //   window.scrollTo(0, topPos);
    //   setTimeout(function () {
    //     window.scrollTo(0, topPos);
    //   }, 1000)
    // }
  }

  setHeadTags(model) {
    const additionalText = model.page > 1 ? ` | page ${model.page}` : '';
    const description = model.subCategory.firstObject.seoDescription;
    const headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.util.truncate(description, 170, true) + additionalText,
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: model.subCategory.firstObject.keywords,
        },
      },
    ];

    this.set('headTags', headTags);
  }

  model({
    page,
    size,
    ...params
  }) {
    const rubric = this.modelFor('catalog.rubric');
    const {
      category,
      subCategory,
      filters,
    } = this.modelFor(
      'catalog.rubric.category.sub-category',
    );

    const filtersQP = {
      'filter[sub_category]': subCategory.firstObject.id,
    };

    Object.keys(params).forEach(name => {
      if (!isEmpty(params[name])) {
        if (name != 'sortVal' && name != 'sortDir') {
          filtersQP[`filter[${name}]`] = params[name];
        }
      }
    });

    const order = params.sortDir == 'desc' ? '-' : '';
    const sort = order + (params.sortVal || 'name');

    return hash({
      rubric,
      category,
      subCategory,
      filters,
      products: this.store.query(
        'product',
        Object.assign(filtersQP, {
          sort: sort,
          page: {
            number: page,
            size,
          },
        }),
      ),
      page,
    });
  }

  resetController(controller) {
    const queryParams = controller.get('queryParams');

    queryParams.forEach(function (param) {
      if (!['page', 'size'].includes(param)) {
        controller.set(param, null);
      }
    });
  }
}
