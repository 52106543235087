import Component from '@glimmer/component';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';

export default class HeaderRubricsComponent extends Component {
  @service store;

  @tracked rubrics = [];

  constructor() {
    super(...arguments);

    this.rubrics = this.store.peekAll('rubric');
  }

}
