import Component from '@glimmer/component';
import {
  action,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import {
  computed,
} from '@ember/object';

export default class CartItemsRowComponent extends Component {
  @service cart;
  @service product;
  @service file;
  @service favorites;
  @service session;
  @service intl;

  constructor() {
    super(...arguments);

    this.file.getNoImages();
  }

  get favorite() {
    const product = this.args.item.get('product').get('content');

    if (product) {
      return this.favorites.getFavoriteProduct(product);
    }

    return null;
  }

  get favInfo() {
    if (this.session.isAuthenticated) {
      if (this.favorite) {
        return this.intl.t('favorite.userHelpInfo.remove');
      }

      return this.intl.t('favorite.userHelpInfo.add');
    }

    return this.intl.t('favorite.guessHelpInfo');
  }

  get getProductData() {
    return this.product.getProductData(this.args.item.product.get('content'));
  }

  @computed('args.item')
  get itemFormatted() {
    return this.args.item;
  }



  get priceWtax() {
    return parseFloat(this.args.item.price * 1.2).toFixed(2);
  }

  @action
  plusItem(product) {
    this.cart.plusItem(product);
  }

  @action
  minusItem(product) {
    this.cart.minusItem(product);
  }

  @action
  removeItem(product) {
    this.cart.removeItem(product);
  }

  @action
  updateQuantity(product, event) {
    if (event.target.value <= 0) return;
    this.cart.updateQuantity(product, event.target.value);
  }

  @action
  async removeFavorite() {
    await this.favorites.removeFavoriteProduct(this.args.item.get('product').get('content'));
  }

  @action
  async addFavorite() {
    await this.favorites.addFavoriteProduct(this.args.item.get('product').get('content'));
  }
}
