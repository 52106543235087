import Route from '@ember/routing/route';

export default class NotFoundRoute extends Route {
  constructor() {
    super(...arguments);
  }

  beforeModel() {
    this.replaceWith('home');
  }
}
