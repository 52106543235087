import Controller from '@ember/controller';
import {
  action,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import {
  tracked,
} from '@glimmer/tracking';
import {
  task,
} from 'ember-concurrency-decorators';
import {
  computed,
} from '@ember/object';

export default class ExpressController extends Controller {
  @service currentUser;
  @service cart;
  @service intl;
  @service order;
  @service('fake-fastboot') fastboot;
  @service router;

  @tracked comment;
  @tracked sent;
  @tracked req;
  @tracked addCartItems;
  @tracked noAuth;
  @tracked userContact;
  @tracked deliveryAddress;
  @tracked submited;
  @tracked userPermission;

  constructor() {
    super(...arguments);
    if (!this.fastboot.isFastBoot) {
      this.comment = localStorage.getItem('expressOrderText');
    }
  }

  @computed('intl')
  get breadCrumbs() {
    return [{
        label: this.intl.t('common.home'),
        path: 'home',
      },
      {
        label: this.intl.t('order.title'),
        isCurrent: true,
        linkable: false,
      },
    ];
  }

  @action
  selectOption(target) {
    this[target.name] = target.value;
  }

  @task
  makeOrder = function* (event) {
    event.preventDefault();

    yield this.expressOrder();
  };

  @action
  async expressOrder() {
    await this.cart.checkCart();

    this.req = false;
    if (!this.comment) {
      this.req = true;
      return;
    }

    this.submited = true;

    const contact = await this.order.getContact(this.userContact);
    const address = await this.order.getAddress(this.deliveryAddress);

    if (!contact || !address) return;

    await this.order.checkUser();
    if (this.order.userPermission) {
      this.addCartItems = false;
      return;
    }
    if (this.cart.cartData.cartItems.length) {
      this.addCartItems = true;
      return;
    }
    if (this.order.userPermission) {
      this.addCartItems = false;
      return;
    }
    this.saveOrder();
  }

  @action
  async saveOrder() {
    if (!this.currentUser.user) {
      this.noAuth = true;
      return;
    }

    await this.order.createOrder(this.userContact, this.deliveryAddress, this.comment);

    if (!this.fastboot.isFastBoot) {
      localStorage.removeItem('expressOrderText');
    }
    this.addCartItems = false;
  }

  @action
  async saveOrderWithItems() {
    await this.saveOrder();

    await this.order.addItems();

    this.addCartItems = false;
  }

  @action
  noAuthCancel() {
    this.noAuth = false;
  }

  @action
  blurHandler(value) {
    if (!this.fastboot.isFastBoot) {
      localStorage.setItem('expressOrderText', value);
    }
  }

  @action
  auto_grow(element) {
    element.style.height = '5px';
    element.style.height = (element.scrollHeight) + 'px';
  }
}
