import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { computed } from '@ember/object'
import { inject as service } from '@ember/service';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';

import config from 'bumgild-app-client/config/environment';

export default class ApplicationAdapter extends JSONAPIAdapter.extend(DataAdapterMixin) {
  @service session;

  host = config.API.host;
  namespace = config.API.namespace;
  coalesceFindRequests = true;

  @computed('session.data.authenticated.access_token')
  get headers() {
    const headers = {
      'API-KEY': config.API.apiKey,
    };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }

  shouldReloadAll() {
    return false;
  }
}
