import Controller from '@ember/controller';
import {
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class CatalogRubricCategorySubCategoryProductsController extends Controller {
  @service intl;
  @service('fake-fastboot') fastboot;

  @computed('model')
  get breadCrumbs() {

    if (!this.fastboot.isFastBoot) {
      window.scrollTo(0, 0);
    }

    return [{
        label: this.intl.t('common.home'),
        path: 'home',
      },
      {
        label: this.intl.t('catalog.title'),
        path: 'catalog',
      },
      {
        label: this.model.rubric.firstObject.name,
        path: 'catalog.rubric',
        model: this.model.rubric.firstObject.slug,
      },
      {
        label: this.model.category.firstObject.name,
        path: 'catalog.rubric.category',
        model: this.model.category.firstObject.slug,
      },
      {
        label: this.model.subCategory.firstObject.name,
        path: 'catalog.rubric.category.sub-category',
        model: this.model.subCategory.firstObject.slug,
      },
      {
        label: this.model.product.firstObject.name,
        isCurrent: true,
        linkable: false,
      },
    ];
  }
}
