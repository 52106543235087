import Component from '@glimmer/component';
import {
  computed,
} from '@ember/object';
import ENV from '../../../../config/environment';

export default class CartItemsRowComponent extends Component {
  re = /(?:\.([^.]+))?$/;
  apiHost = ENV.API.host;

  @computed('args.file')
  get ext() {
    return '.' + this.re.exec(this.args.file.fileUrl)[1];
  }

  @computed('ext')
  get icon() {
    const formats = {
      pdf: ['.pdf'],
      word: [
        '.doc',
        '.docx',
        '.docm',
        '.dotx',
        '.dotm',
        '.docb',
        '.dot',
        '.wbk',
      ],
      excel: [
        '.xlsx',
        '.xlsm',
        '.xlsb',
        '.xltx',
        '.xltm',
        '.xls',
        '.xlt',
        '.xml',
        '.xlam',
        '.xla',
        '.xlw',
        '.xlr',
      ],
      video: [
        '.webm',
        '.mkv',
        '.flv',
        '.f4v',
        '.f4p',
        '.f4a',
        '.f4b',
        '.vob',
        '.ogv',
        '.ogg',
        '.drc',
        '.avi',
        '.MTS',
        '.M2TS',
        '.TS',
        '.mov',
        '.qt',
        '.wmv',
        '.rm',
        '.rmvb',
        '.amv',
        '.mp4',
        '.m4p',
        '.m4v',
        '.mpg',
        '.mp2',
        '.mpeg',
        '.mpe',
        '.mpv',
        '.m2v',
        '.m4v',
        '.svi',
        '.3gp',
        '.3g2',
        '.mxf',
      ],
      image: [
        '.tif',
        '.tiff',
        '.bmp',
        '.jpg',
        '.jpeg',
        '.gif',
        '.png',
        '.eps',
        '.raw',
        '.cr2',
        '.nef',
        '.orf',
        '.sr2',
      ],
      audio: [
        '.aa',
        '.aac',
        '.aax',
        '.act',
        '.aiff',
        '.alac',
        '.amr',
        '.ape',
        '.au',
        '.awb',
        '.dct',
        '.dss',
        '.dvf',
        '.flac',
        '.gsm',
        '.iklax',
        '.ivs',
        '.m4a',
        '.m4b',
        '.m4p',
        '.mmf',
        '.mp3',
        '.mpc',
        '.msv',
        '.nmf',
        '.nsf',
        '.wav',
        '.wma',
        '.webm',
      ],
      archive: [
        '.ar',
        '.cpio',
        '.shar',
        '.tar',
        '.bzip2',
        '.compress',
        '.gzip',
        '.7z',
        '.dar',
        '.dmg',
        '.egg',
        '.rzio',
        '.zip',
        '.xar',
        '.gz',
      ],
    };
    let type;
    if (formats.pdf.includes(this.ext)) {
      type = '-pdf';
    }

    if (formats.word.includes(this.ext)) {
      type = '-word';
    }

    if (formats.excel.includes(this.ext)) {
      type = '-excel';
    }

    if (formats.video.includes(this.ext)) {
      type = '-video';
    }

    if (formats.image.includes(this.ext)) {
      type = '-image';
    }

    if (formats.audio.includes(this.ext)) {
      type = '-audio';
    }

    if (formats.archive.includes(this.ext)) {
      type = '-archive';
    }

    return 'file' + type;
  }
}
