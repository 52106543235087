import Component from '@glimmer/component';
import {
  tracked,
} from '@glimmer/tracking';
import {
  action,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class CategoriesNavComponent extends Component {
  @service router;
  @service store;

  @tracked rubrics = [];
  @tracked activeCategoryId;

  constructor() {
    super(...arguments);

    this.rubrics = this.store.peekRecord(
      'rubric',
      this.args.rubric.id
    );
    // this.rubrics = this.store.findRecord(
    //   'rubric',
    //   this.args.rubric.id, {
    //     include: 'categories,sub_categories',
    //   },
    // );
  }

  @action
  toggleCategory(category, event) {
    event.preventDefault();
    event.stopPropagation();

    if (category.id === this.activeCategoryId) {
      this.activeCategoryId = null;
    } else {
      this.activeCategoryId = category.id;
    }
  }
}
