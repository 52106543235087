import Model, {
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';

export default class UserModel extends Model {
  @attr('string') email;
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') organization;
  @attr('string') phone;
  @attr('string') unp;
  @attr('boolean') user_role;
  @belongsTo('account') account;
  @belongsTo('manager') manager;
  @hasMany('order') orders;
  @hasMany('deliveryAddress') deliveryAddresses;
  @hasMany('userContact') userContacts;
}
