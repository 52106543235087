import Controller from '@ember/controller';
import {
  action,
} from '@ember/object';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';
import {
  task,
} from 'ember-concurrency-decorators';

export default class LoginController extends Controller {
  @service axios;
  @service('fake-fastboot') fastboot;
  @service intl;

  @tracked email;
  @tracked errorMessage;
  @tracked used = false;
  @tracked agreement = false;

  @action
  handleInput(field, event) {
    this[field] = event.target.value.toLowerCase();
  }
  model() {
    return this.store.query('text-block', {
      filter: {
        title: 'greeting',
      },
      sort: 'id',
      page: {
        number: 1,
        size: 1,
      },
    })
  }
  @task
  registration = function* (event) {
    event.preventDefault();
    if (!this.email) return;
    this.errorMessage = '';
    if (!this.agreement) return;
    this.errorMessage = '';
    yield this.axios
      .post('registration', {
        email: this.email,
      })
      .then(res => {
        if (res.status == 204) {
          this.used = true;
          if (!this.fastboot.isFastBoot)
            localStorage.setItem('registrationMail', this.email);
        } else {
          this.errorMessage = res.data.message == 'User exist' ? this.intl.t('registration.userExist') : res.data.message;
        }
      });
  };
}
