import Service from '@ember/service';
import {
  inject as service,
} from '@ember/service';
import {
  tracked,
} from '@glimmer/tracking';
import ENV from 'bumgild-app-client/config/environment';

export default class FileService extends Service {
  @service store;

  @tracked noImage;
  @tracked logo;

  apiHost = ENV.API.host;

  async getNoImages() {
    if (this.noImage) return;

    await this.store
      .query('download', {
        filter: {
          title: 'no_foto',
        },
        sort: 'id',
        page: {
          number: 1,
          size: 1,
        },
      })
      .then(noImage => {
        this.noImage = this.apiHost + noImage.firstObject.file.url;
      });
  }

  async getLogo() {
    if (this.logo) return;

    await this.store
      .query('download', {
        filter: {
          title: 'logotype',
        },
        sort: 'id',
        page: {
          number: 1,
          size: 1,
        },
      })
      .then(logo => {
        this.logo = this.apiHost + logo.firstObject.file.url;
      });
  }
}
