import Controller from '@ember/controller';
import {
  action,
} from '@ember/object';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';
import {
  task,
} from 'ember-concurrency-decorators';

export default class LoginController extends Controller {
  @service session;
  @service intl;
  @service axios;
  @service router
  @tracked email;
  @tracked password;
  @tracked errorMessage;
  @tracked locked;
  @tracked message;

  @action
  handleInput(field, event) {
    this[field] = event.target.value.toLowerCase();
  }

  @task
  login = function* (event) {
    event.preventDefault();

    this.errorMessage = '';
    if (!this.email || !this.password) return;

    try {
      yield this.session.authenticate(
        'authenticator:oauth2',
        this.email,
        this.password,
      );
      yield this.router.transitionTo('home')
    } catch (reason) {
      if(reason.responseJSON.error){
        const mess = reason.responseJSON.error.trim().split(' ').join('_');
        this.errorMessage = this.intl.t('errors.' + mess);
        if(mess === 'Your_account_is_locked.') this.locked = true;
      }else{
        this.errorMessage = reason;
        console.log('errorMessage ->', reason);
      }
    }
  };

  @task
  unlock = function* (event) {
    event.preventDefault();

    if (!this.email) return;
    this.errorMessage = '';

    yield this.axios
      .post('unlock', {
        email: this.email,
      })
      .then(res => {
        if (res.status == 200) {
          this.message = this.intl.t('common.unlocked');
        } else {
          this.errorMessage = res.data.message;
        }
      });
  };
}
