import Component from '@glimmer/component';
import {
  tracked,
} from '@glimmer/tracking';
import {
  action,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import {
  task,
} from 'ember-concurrency-decorators';

export default class PersonalAddressesRowComponent extends Component {
  @service store;
  @tracked activeEdit;
  @tracked submited;

  @action
  edit() {
    this.activeEdit = true;
    this.submited = false;
  }

  @action
  remove() {
    this.args.adressData.set('user', '');
    this.args.adressData.save();
  }

  @action
  cancelUpdate() {
    this.activeEdit = false;
  }

  @task
  update = function* (event) {
    event.preventDefault();

    this.activeEdit = false;

    yield this.args.adressData.save()
      .then(() => {
        this.submited = true
      })
      .catch(() => {
        this.submited = true
      });
  }
}
