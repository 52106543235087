import Route from '@ember/routing/route';
import {
  hash,
} from 'rsvp';
import {
  inject as service,
} from '@ember/service';

export default class CatalogRubricIndexRoute extends Route {
  constructor() {
    super(...arguments);
  }

  @service intl;
  @service util;

  afterModel(model) {
    // console.log(model.no_sale)
    // if (model.saleProducts.length == 0 && model.rubric.firstObject && model.rubric.firstObject.categories.firstObject) {
    //   if (model.rubric.firstObject.slug && model.rubric.firstObject.categories.firstObject.slug) {
    //     this.transitionTo('/catalog/' + model.rubric.firstObject.slug + '/' + model.rubric.firstObject.categories.firstObject.slug);
    //     return;
    //   }
    // }

    this.setHeadTags(model);
  }

  setHeadTags(model) {
    const description = model.rubric.firstObject.seoDescription;
    const headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.util.truncate(description, 170, true),
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: model.rubric.firstObject.keywords,
        },
      },
    ];

    this.set('headTags', headTags);
  }

  model() {
    const rubric = this.modelFor('catalog.rubric');

    return hash({
      rubric,
      saleProducts: this.store.query(
        'product', {
          'filter[rubric]': rubric.firstObject.id,
          'filter[is_sale]': true,
        }, {
          sort: 'name',
        },
      ),
      no_sale: this.store.query('text-block', {
        filter: {
          title: [
            'noSale',
          ],
        },
        sort: 'id',
        page: {
          number: 1,
          size: 1,
        },
      }),
    });
  }
}
