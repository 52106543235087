import { helper } from '@ember/component/helper';

function getBase64FromSrc(src) {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      let dataURL;
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL();
      resolve(dataURL);
    }
      img.src = src;
  })
}
export default helper(async function ([src]) {
  return await getBase64FromSrc(src);
});
