import Controller from '@ember/controller';
import {
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import ENV from '../../config/environment';

export default class ContactController extends Controller {
  @service intl;
  apiHost = ENV.API.host;

  @computed('intl')
  get breadCrumbs() {
    return [{
        label: this.intl.t('common.home'),
        path: 'home',
      },
      {
        label: this.intl.t('contacts.title'),
        isCurrent: true,
        linkable: false,
      },
    ];
  }
}
