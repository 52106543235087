import Route from '@ember/routing/route';
import {
  inject as service,
} from '@ember/service';

export default class CartRoute extends Route {
  @service intl;
  @service currentUser;

  constructor() {
    super(...arguments);
  }

  afterModel() {
    this.setHeadTags();
  }

  setHeadTags() {
    let headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.intl.t('common.desc'),
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: this.intl.t('common.keywords'),
        },
      },
    ];

    this.set('headTags', headTags);
  }
}
