import Route from '@ember/routing/route';
import {
  hash,
} from 'rsvp';

export default class CatalogRubricCategoryRoute extends Route {

  afterModel(model) {
    if(!model){
      this.transitionTo('/not-found');
      return;
    }
  }

  async model({
    category_slug,
  }) {

    const category = await this.store.query('category', {
      filter: {
        slug: category_slug,
      },
      sort: 'id',
      page: {
        number: 1,
        size: 1,
      },
      include: 'sub_categories',
    });

    if(category.get('length') === 0) {
      return null;
    }

    return hash({
      category,
      filters: await this.store.query('filter', {
        category_slug,
      }),
    });
  }
}
