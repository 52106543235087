import Route from '@ember/routing/route';
import {
  action,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import {
  scheduleOnce,
} from '@ember/runloop';
import {
  isEmpty,
} from '@ember/utils';

import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';

export default class ApplicationRoute extends Route.extend(ApplicationRouteMixin) {
  @service intl;
  @service metrics;
  @service router;
  @service currentUser;
  @service session;
  @service favorites;
  @service moment;

  async beforeModel(transition) {
    super.beforeModel(...arguments);

    this.routeAfterAuthentication = 'home';
    this.moment.setLocale('ru');
    this.intl.setLocale(['ru']);
    await this._loadCurrentUser();

    this.setCurrentUrlToSession(transition);
  }

  _loadCurrentUser() {
    return this.currentUser.load().catch(() => this.session.invalidate());
  }

  async sessionAuthenticated() {
    await this._loadCurrentUser();

    super.sessionAuthenticated(this, ...arguments);

    await this.favorites.fetchFavorites();

    const previousRoute = this.session.previousRouteName;
    if (previousRoute) {
      this.transitionTo(previousRoute);
    }
  }

  async model() {
    return this.store.findAll('rubric')
  }

  @action
  didTransition() {
    // eslint-disable-next-line
    this._super(...arguments);
    this._trackPage();
  }

  @action
  willTransition(transition) {
    this.setCurrentUrlToSession(transition);
  }

  setCurrentUrlToSession(transition) {
    transition.then(() => {
      let url;

      if (isEmpty(transition.params)) {
        url = transition.router.generate(transition.targetName);
      } else {
        url = transition.router.generate(transition.targetName, transition.params);
      }

      if (!url.includes('login')) {
        this.session.previousRouteName = url;
      }
    });
  }

  _trackPage() {
    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', () => {
      const page = this.router.currentURL;
      const title = this.router.currentRouteName;

      this.metrics.trackPage({
        page,
        title,
      });
    })
  }
}
