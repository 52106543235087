import Component from '@glimmer/component';

export default class ProductViewSelectorComponent extends Component {
  viewTypes = [{
    icon: 'list',
    name: 'list',
  }, {
    icon: 'th',
    name: 'module',
  }, {
    icon: 'bars',
    name: 'stream',
  }];
}
