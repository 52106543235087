import Component from '@glimmer/component';
import {
  action,
  notifyPropertyChange,
  computed,
  set,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class FiltersComponent extends Component {
  @service router;
  @service store;

  @computed('router.currentRoute.queryParams')
  get activeFilters() {
    return this.router.currentRoute.queryParams;
  }

  @computed('activeFilters')
  get openFilters() {
    return Object.keys(this.activeFilters);
  }

  @action
  toggleFilter(filter) {
    let openFilters = this.openFilters;

    if (this.openFilters.includes(filter.code)) {
      openFilters = this.openFilters = this.openFilters.filter(filterName => {
        if (filterName !== filter.code) {
          return filter.code;
        }
      });
    } else {
      openFilters.push(filter.code);
    }

    set(this, 'openFilters', openFilters);
    notifyPropertyChange(this, 'openFilters');
  }
}
