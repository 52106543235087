import Controller from '@ember/controller';
import {
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class OplataController extends Controller {
  @service intl;

  @computed('intl')
  get breadCrumbs() {
    return [{
        label: this.intl.t('common.home'),
        path: 'home',
      },
      {
        label: this.intl.t('payment.title'),
        isCurrent: true,
        linkable: false,
      },
    ];
  }
}
