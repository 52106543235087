import Component from '@glimmer/component';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';
import {
  task,
} from 'ember-concurrency-decorators';

export default class PersonalAddressesComponent extends Component {
  @service store;
  @service currentUser;
  @tracked address;

  @task
  addAddress = function* (event) {
    event.preventDefault();

    if (!this.address) return;

    const newAddress = this.store.createRecord('delivery-address', {
      address: this.address,
      user: this.currentUser.user,
    });

    yield newAddress
      .save()
      .then(() => {
        this.address = '';
      });
  };
}
