import Model, {
  attr,
  hasMany,
  belongsTo,
} from '@ember-data/model';

export default class OrderModel extends Model {
  @attr('string') status;
  @attr('number') totalPrice;
  @attr('string') memo;
  @attr('boolean') self_delivery;
  @attr('date') shiped_at;
  @attr('date') createdAt;

  @belongsTo('user') user;
  @belongsTo('user-contact') user_contact;
  @belongsTo('delivery-address') delivery_address;
  @belongsTo('account') account;
  @hasMany('orderItem') orderItems;
  @hasMany('orderFile') orderFiles;
}
