import {
  helper,
} from '@ember/component/helper';

export default helper(function ifIn(params) {
  const arr = params[0];
  const str = params[1];
  if (arr.length) {
    if (arr.includes(str)) return true;
  }

  return false;
});
