import Component from '@glimmer/component';
import {
  inject as service,
} from '@ember/service';
import {
  action,
  computed,
} from '@ember/object';
import {
  tracked,
} from '@glimmer/tracking';
import {
  task,
} from 'ember-concurrency-decorators';

export default class PersonalDataComponent extends Component {
  @service currentUser;
  @service store;
  @service util;

  @tracked submited = false;
  @tracked success = false;
  @tracked phone = this.currentUser?.user?.phone?.substring(3);
  @tracked unpError;
  // @tracked password;
  // @tracked repeatPassword;

  @computed('router')
  get unpInfo() {
    return this.store.query('text-block', {
      filter: {
        title: 'unn',
      },
      sort: 'id',
      page: {
        number: 1,
        size: 1,
      },
    })
  }

  @action
  updatePhone(e) {
    this.phone = e;
  }

  @task
  update = function* (event) {
    event.preventDefault();

    this.unpError = false;
    this.submited = true;

    if (
      !this.currentUser.user.email ||
      !this.currentUser.user.firstName ||
      !this.currentUser.user.unp ||
      !this.currentUser.user.phone
    )
      return;

    if (this.unpInfo.lastObject.body == 1 && !this.util.checkUNP(this.currentUser.user.unp)) {
      this.unpError = true;
      return;
    }

    if (this.phone.length == 9) {
      this.currentUser.user.set('phone', '375' + this.phone);
    }

    // if (this.password) {
    //   if (this.password.length < 6) return;
    //   if (this.password != this.repeatPassword) return;
    //   this.currentUser.user.password = this.password;
    // }

    this.success = false;

    yield this.currentUser.user.save().then(() => {
      this.success = true;

      setTimeout(() => {
        this.success = false;
      }, 3000);
    });
  }
}
