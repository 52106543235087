import Model, {
  attr,
  belongsTo,
} from '@ember-data/model';
import {
  alias,
} from '@ember/object/computed';
import { htmlSafe } from '@ember/template';

export default class DownloadModel extends Model {
  @attr('string') title;
  @attr() file;
  @attr('string') desc;
  @attr('string') keywords;
  @belongsTo('download-group') downloadGroup;
  @alias('file.url') fileUrl;
  get getDesc() {
    htmlSafe(this.desc)
  }
}
