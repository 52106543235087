import Model, {
  attr,
  belongsTo,
} from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class UserContactModel extends Model {

  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') phone1;
  @attr('string') phone2;
  @attr('string') email;
  @attr('string') desc;

  @belongsTo('user') user;

  get getDesc() {
    htmlSafe(this.desc)
  }
}
