import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import {
  task,
} from 'ember-concurrency-decorators';

export default class HeaderSearchComponent extends Component {
  @service store;
  @service intl;

  @tracked searchResults = [];
  @tracked searchValue = '';
  @tracked searching = false;
  @tracked showSearchResult = false;
  @tracked noResultText = '';
  @tracked showPopup = 'hidden';

  revertState(searchValue) {
    this.showSearchResult = false;
    this.showPopup = 'hidden';
    this.searchResults = [];
    this.searchValue = searchValue;
    this.noResultText = '';
  }

  @action
  closePopup() {
    this.showPopup = 'hidden';
  }

  @action
  handleInput(field, event) {
    this[field] = event.target.value;
  }

  @action
  handleKeyUp(event) {
    if (event.code === 'Enter' || event.which === 13) {
      this.search.perform(event);
    }
  }

  @action
  clearSearch() {
    this.revertState();
  }

  @action
  handleOutClick() {
    this.revertState(this.searchValue);
  }

  @task({
    restartable: true,
  })
  search = function* (event) {
    event.preventDefault();

    this.searchResults = [];
    this.showSearchResult = false;
    this.showPopup = 'hidden';

    if (!this.searchValue || this.searchValue.length < 3) {
      this.noResultText = this.intl.t('header.search.minSymbolCountText');
      this.showSearchResult = true;
      this.showPopup = '';

      return;
    }
    this.searching = true;

    yield this.store.query('product', {
      filter: {
        search: this.searchValue,
      },
      sort: 'name',
      include: 'rubric,category,sub_category',
    }).then(data => {
      this.searchResults = data;
      this.searching = false;

      if (!data.length) {
        this.noResultText = this.intl.t('header.search.noResultText', { searchValue: this.searchValue });
      }
      this.showSearchResult = true;
      this.showPopup = '';
    });
  }
}
