import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import ENV from '../../../config/environment';

export default class ManagerItemComponent extends Component {
  apiHost = ENV.API.host;

  get style() {
    const style = `background-image: url(${this.apiHost}${this.args.manager.image.url});`;

    return htmlSafe(style);
  }
}
