import ApplicationAdapter from './application';

export default class DeliveryAddressAdapter extends ApplicationAdapter {
  createRecord(store, type, snapshot) {
    const url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
    const data = this.serialize(snapshot, { includeId: true });
    data.data.type = 'delivery_addresses';

    return this.ajax(url, 'POST', { data });
  }

  updateRecord(store, type, snapshot) {
    const url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
    const data = this.serialize(snapshot, { includeId: true });
    data.data.type = 'delivery_addresses';

    return this.ajax(url, 'PUT', { data });
  }

}
