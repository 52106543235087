import Component from '@glimmer/component';
import {
  action,
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class HeaderComponent extends Component {
  @service session;
  @service currentUser;
  @service cart;
  @service favorites;
  @service order;
  @service file;
  @service store;

  constructor() {
    super(...arguments);
    this.cart.getCart();
    // this.file.getLogo();
  }

  @computed('store')
  get headerBlock() {
    return this.store.query('text-block', {
      filter: {
        title: [
          'main0',
        ],
      },
      sort: 'title',
      page: {
        number: 1,
        size: 1,
      },
    });
  }

  @action
  logout() {
    this.session.invalidate();
  }
}
