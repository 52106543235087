import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PersonalHystoryRowOrderItemComponent extends Component {
  @service intl;
  @service favorites;
  @service session;

  get favorite() {
    const product = this.args.item.get('product').get('content');

    if (product) {
      return this.favorites.getFavoriteProduct(product);
    }

    return false;
  }

  get favInfo() {
    if (this.session.isAuthenticated) {
      if (this.favorite) {
        return this.intl.t('favorite.userHelpInfo.remove');
      }

      return this.intl.t('favorite.userHelpInfo.add');
    }

    return this.intl.t('favorite.guessHelpInfo');
  }

  @action
  async removeFavorite() {
    await this.favorites.removeFavoriteProduct(this.args.item.get('product').get('content'));
  }

  @action
  async addFavorite() {
    await this.favorites.addFavoriteProduct(this.args.item.get('product').get('content'));
  }
}
