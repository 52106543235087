import Model, {
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class ProductModel extends Model {
  @attr('string') availability;
  @attr('string') additionalDesc;
  @attr('string') code;
  @attr('string') desc;
  @attr('string') expected;
  @attr() imageData;
  @attr('boolean') hidden;
  @attr('boolean') isSale;
  @attr('boolean') isStock;
  @attr('boolean') onOrder;
  @attr('boolean') isReserved;
  @attr('boolean') isPromotion;
  @attr('string') keywords;
  @attr('string') name;
  @attr('string') order;
  @attr('string') slug;
  @attr('string') sku;
  @attr('string') unit;
  @attr('number') price
  @attr('string') seoTitle;
  @attr('string') seoDescription;
  @attr('string') seoH1;
  @attr('string') seoH2;

  @belongsTo('rubric') rubric;
  @belongsTo('category') category;
  @belongsTo('subCategory') subCategory;
  @hasMany('productAttribute') productAttributes;

  get image() {
    try {
      const sortedImageData = this.imageData.sort((a, b) => a.sort_order - b.sort_order);
      return sortedImageData[0];
    }
    catch (e) {
      console.log(e)
    }

    
  }
  get getDesc() {
    return htmlSafe(this.desc)
  }
}
