import { helper } from '@ember/component/helper';

export default helper(function isActiveFilterOption([option, activeFilters ]/*, hash*/) {
  if (!activeFilters[option.code]) {
    return false;
  }

  const temp = activeFilters[option.code].split(',');

  return temp.includes(option.name);
});
