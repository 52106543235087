import Route from '@ember/routing/route';

export default class CatalogRubricRoute extends Route {

  afterModel(model) {
    if (model.get('length') === 0) {
      this.transitionTo('/not-found');
      return;
    }

    this.breadCrumb = {
      title: model.name,
    }
  }

  // @computed('model')
  // get breadCrumb() {
  //   return {
  //     title: this.model.rubric.name,
  //   };
  // }

  // afterModel() {
  //   super.afterModel(...arguments);

  // if (!this.fastboot.isFastBoot) {
  //   const sFull = document.getElementById('header-sFull');
  //   const sSmall = document.getElementById('header-sSmall');

  //   let h = sFull ? sFull.offsetHeight : 0;
  //   if (h === 0) {
  //     h = sSmall ? sSmall.offsetHeight - 16 : 0;
  //   }

  //   const element = document.getElementById('breadcrumbs');
  //   const topPos =
  //     element.getBoundingClientRect().top + window.scrollY - h - 22;

  //   window.scrollTo(0, topPos);
  //   setTimeout(function () {
  //     window.scrollTo(0, topPos);
  //   }, 1000)
  // }
  // }

  model({
    rubric_slug,
  }) {
    return this.store.query('rubric', {
      filter: {
        slug: rubric_slug,
      },
      sort: 'id',
      page: {
        number: 1,
        size: 1,
      },
      include: 'categories',
    });
  }
}
