import Controller from '@ember/controller';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class CatalogRubricCategorySubCategoryIndexController extends Controller {
  @service router;
  @service intl;
  @service('fake-fastboot') fastboot;

  @tracked ready = false;
  @tracked seeDesc;

  queryParams = [
    'page',
    'size',
    'sortVal',
    'sortDir',
    'brand',
    'weight',
    'glubinazachvata',
    'diametr',
    'vtulka',
    'dlina',
    'klass',
    'linovka',
    'material',
    'nasyischennost',
    'plotnost',
    'poverhnost',
    'probivaet_listov',
    'razmer',
    'razryad',
    'seria',
    'skoba',
    'sshivaetlistov',
    'tverdost',
    'tip',
    'tolschina',
    'format',
    'colour',
    'shirina',
     ];
  page = 1;
  size = 10;

  @computed('model')
  get breadCrumbs() {
    return [{
        label: this.intl.t('common.home'),
        path: 'home',
      },
      {
        label: this.intl.t('catalog.title'),
        path: 'catalog',
      },
      {
        label: this.model.rubric.firstObject.name,
        path: 'catalog.rubric',
        model: this.model.rubric.firstObject.slug,
      },
      {
        label: this.model.category.firstObject.name,
        path: 'catalog.rubric.category',
        model: this.model.category.firstObject.slug,
      },
      {
        label: this.model.subCategory.firstObject.name,
        isCurrent: true,
        linkable: false,
      },
    ];
  }

  @computed('model.subCategory.firstObject.desc')
  get descHeight() {

      const newElement = document.createElement('div');
      newElement.innerHTML = this.model.category.firstObject.desc;

      document.body.appendChild(newElement);

      const descHeight = newElement.getBoundingClientRect().height;

      document.body.removeChild(newElement);

      return descHeight;

  }

  get descStyle() {
    if (!this.showDescToggler) {
      return htmlSafe('');
    }

    const style = this.seeDesc ? '' : 'height: 38px; overflow:hidden';

    return htmlSafe(style);
  }

  get showDescToggler() {
    return this.descHeight > 30;
  }

  @action
  toogleDesc(event) {
    event.preventDefault();

    this.seeDesc = !this.seeDesc;
  }

  @computed('model.products.meta.page_count')
  get pagination() {
    let result = [];

    for (let i = 1; i <= this.model.products.get('meta.page_count'); i++) {
      result.push(i);
    }

    if (result.length < 2) {
      result = [];
    }

    return result;
  }

  @action
  toggleFilter({
    code,
    name,
  }) {
    if (!isEmpty(this[code])) {
      const currentValues = this[code].split(',');
      if (currentValues.includes(name)) {
        this[code] = this[code]
          .split(',')
          .filter(filterOption => filterOption !== name)
          .join(',');
      } else {
        this[code] = this[code].concat(',', name);
      }
    } else {
      this[code] = name;
    }

    if (isEmpty(this[code])) {
      this[code] = null;
    }

    return this.router.transitionTo({
      queryParams: {
        [code]: this[code],
        page: 1,
      },
    });
  }

  @action
  sort({
    code,
    value,
  }) {
    this[code] = value;
    this.sortDir = this.sortVal ? this.sortDir : '';

    return this.router.transitionTo({
      queryParams: {
        [code]: this[code],
      },
    });
  }

  @action
  setSort(selected) {
    this.sort({
      code: 'sortVal',
      value: selected,
    });
  }

  @action
  setDir(selected) {
    this.sort({
      code: 'sortDir',
      value: selected,
    });
  }
}
