import Route from '@ember/routing/route';
import {
  hash,
} from 'rsvp';
import {
  inject as service,
} from '@ember/service';


export default class extends Route {
  @service intl;

  async model(param) {
    let res = await this.store.query('text-block', {
      filter: {
        id: param.id,
      },
    });
    return res.firstObject;
  }

  afterModel(resolvedModel) {
    const descr = `${ 
      resolvedModel.text_description !== undefined
        ? resolvedModel.text_description
        : resolvedModel.text_title !== undefined
          ? resolvedModel.text_title
          : resolvedModel.text_h1
    }`;
    console.log(descr);
    this.setHeadTags(descr);
  }

  setHeadTags(descr) {
    let headTags = [
      {
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: descr,
        },
      },
    ];

    this.set('headTags', headTags);
  }

}
