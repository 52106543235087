import EmberRouter from '@ember/routing/router';
import config from './config/environment';

class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('home', {
    path: '/',
  });

  this.route('catalog', function () {
    this.route(
      'rubric', {
        path: '/:rubric_slug',
      },
      function () {
        this.route(
          'category', {
            path: '/:category_slug',
          },
          function () {
            this.route('products', {
              path: '/:product_slug',
            });

            this.route(
              'sub-category', {
                path: '/s/:sub_category_slug',
              },
              function () {
                this.route('products', {
                  path: '/:product_slug',
                });
              },
            );
          },
        );
      },
    );
  });

  this.route('cart');
  this.route('order');
  this.route('login');
  this.route('registration', {
    path: '/registration/:token',
  });
  this.route('not-found', {
    path: '/*path',
  });
  this.route('signup');
  this.route('contact');
  this.route('oplata');
  this.route('aboutcompany');
  this.route('personal');
  this.route('favorites');
  this.route('express');

  this.route('file', function () {
    this.route('download-group', {
      path: '/:group_id',
    });
  });
  this.route('password-repair');
  this.route('password-reset', {
    path: '/password-reset/:token',
  });
  this.route('page-404', {
    path: '404',
  });
  this.route('index-html', {
    path: 'index.html',
  });
  this.route('blog');
  this.route('blog-detail', { path: '/blog/:id' });  
  this.route('policy');
});

export default Router;
