import MainSerializer from './main';
import { spritUnnecessaryTags } from '../utils';

export default class CategorySerializer extends MainSerializer {
  normalizeResponse(store, primaryModelClass, payload) {
    if (payload.data instanceof Array) {
      for (const item of payload.data) {
        item.attributes.desc = spritUnnecessaryTags(item.attributes.desc);
      }
    } else {
      payload.data.attributes.desc = spritUnnecessaryTags(payload.data.attributes.desc);
    }

    return super.normalizeResponse(...arguments);
  }
}
