import Controller from '@ember/controller';
import {
  computed,
} from '@ember/object';

export default class HomeController extends Controller {

  breakpoints = {
    510: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
  };

  @computed('category')
  get saleProducts() {
    return this.store.query(
      'product',
      Object.assign({
        'filter[is_sale]': true,
      }, {
        include: 'category,sub_category',
        page: {
          number: 1,
          size: 20,
        },
      }),
    );
  }

  get group() {
    const data = this.model.toArray();
    const firstItem = data.splice(0, 1);
    const elementsInThrees = [firstItem];
    let currentGroup = [];

    data.forEach((el, idx) => {
      if (idx % 2 === 0) {
        if (currentGroup.length) {
          elementsInThrees.push(currentGroup);
        }

        currentGroup = [];
      }

      currentGroup.push(el);
    });

    if (currentGroup.length) {
      elementsInThrees.push(currentGroup);
    }

    return elementsInThrees;
  }
}
