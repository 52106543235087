import Route from '@ember/routing/route';
import {
  inject as service,
} from '@ember/service';

export default class PersonalRoute extends Route {
  @service intl;
  @service currentUser;
  @service session;

  constructor() {
    super(...arguments);
  }

  activate() {
    if (!this.session.isAuthenticated) {
      this.transitionTo('login');
    }
  }

  beforeModel(transition) {

    if (transition.from && (transition.from.name == 'cart' || transition.from.name == 'express')) {
      this.controllerFor('personal').changeTab('hystory')
    }
  }

  afterModel() {
    this.setHeadTags();
  }

  setHeadTags() {
    let headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.intl.t('common.desc'),
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: this.intl.t('common.keywords'),
        },
      },
    ];

    this.set('headTags', headTags);
  }

  model() {
    return this.currentUser.user ?
      this.store.findRecord('user', this.currentUser.user.id, {
        include: 'account,account.manager,orders,user_contacts,delivery_addresses',
      }) :
      null;
  }
}
