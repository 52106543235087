import MainSerializer from './main';
import { spritUnnecessaryTags } from '../utils';

export default class RubricSerializer extends MainSerializer {
  normalizeResponse(store, primaryModelClass, payload) {
    if (payload.data instanceof Array) {
      for (const item of payload.data) {
        item.attributes.desc = spritUnnecessaryTags(item.attributes.desc);
        if(item.relationships.categories.data?.length) {
          for (const item1 of item.relationships.categories.data) {
            if (item1.attributes) {
              item1.attributes.desc = spritUnnecessaryTags(item1.attributes.desc);
            }
          }
        }
      }
    } else {
      payload.data.attributes.desc = spritUnnecessaryTags(payload.data.attributes.desc);
    }

    if(payload.included && payload.included.length) {
      for (const item of payload.included) {
        if (item.attributes && item.attributes.desc) {
          item.attributes.desc = spritUnnecessaryTags(item.attributes.desc);
        }
      }
    }

    return super.normalizeResponse(...arguments);
  }
}
