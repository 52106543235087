import Model, {
  attr,
  belongsTo,
} from '@ember-data/model';

export default class ProductAttributeModel extends Model {
  @attr('string') key;
  @attr('string') value;
  @attr('boolean') isFiltred;
  @attr('string') filterName;
  @attr('string') filterTitle;
  @belongsTo('product') product;
}
