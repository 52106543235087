import Component from '@glimmer/component';
import {
  tracked,
} from '@glimmer/tracking';
import {
  action,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';
import {
  task,
} from 'ember-concurrency-decorators';

export default class PersonalUsersRowComponent extends Component {
  @service store;
  @tracked activeEdit;
  @tracked submited;
  @tracked phone;

  @action
  edit() {
    this.phone = this.args.userData.phone1.substring(3);
    this.activeEdit = true;
    this.submited = false;
  }

  @action
  remove() {
    this.args.userData.set('user', '');
    this.args.userData.save();
  }

  @action
  cancelUpdate() {
    this.activeEdit = false;
  }

  @action
  updatePhone(e) {
    this.phone = e;
  }

  @task
  update = function* (event) {
    event.preventDefault();

    if (this.phone.length == 9) {
      this.args.userData.set('phone1', '375' + this.phone);
    }

    this.activeEdit = false;

    yield this.args.userData.save()
      .then(() => {
        this.submited = true;
      })
      .catch(() => {
        this.submited = true;
      });
  }
}
