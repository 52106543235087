import Controller from '@ember/controller';
import {
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class FileDownloadGroupController extends Controller {
  @service intl;

  queryParams = ['page', 'size'];

  page = 1;
  size = 20;

  @computed('model')
  get breadCrumbs() {
    return [{
        label: this.intl.t('common.home'),
        path: 'home',
      },
      {
        label: this.intl.t('files.title'),
        path: 'file',
      },
      {
        label: this.model.group.title,
        isCurrent: true,
        linkable: false,
      },
    ];
  }

  @computed('model.files.meta.page_count')
  get pagination() {
    let result = [];

    for (let i = 1; i <= this.model.files.get('meta.page_count'); i++) {
      result.push(i);
    }

    if (result.length < 2) {
      result = [];
    }

    return result;
  }

}
