import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed, notifyPropertyChange } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProductListComponent extends Component {
  @service store
  @tracked viewType = 'stream';
  @tracked viewTypeClass = `products-list--${this.viewType}`;
  // @tracked viewTypeClass = 'products-list--list';

  @tracked filters = {
    rubrics: [],
    categories: [],
    subCategories: [],
  };

  @tracked rubricsTopFilter = []

  // constructor () {
  //   super(...arguments);
  //   // this.fetchRubrics()
  // }

  @action
  closePopup(){
    this.args.closePopup();
  }

  @action
  fetchRubrics() {
    let arr = [];
    this.args.products.forEach(product => {
      let p = this.store.query('product', {
        filter: {
          id: product.id
        }
      });
      arr.push(p);
    })
    Promise.all(arr).then(()=>{
      this.store.query('category', {}).then(()=>{
        this.rubricsTopFilter = this.getRrubricsTopFilter();
      })
    })
  }

  @computed('args.products')
  get products() {
    return this.filteredProducts();
  }

  // @computed('args.{products,rubricsFilter}')
  getRrubricsTopFilter() {
    if (this.args.rubricsFilter) {
      let rubrics = [];
      this.args.products.forEach(product => {
        if (product.get('rubric').get('id')) {
          let category;
          let subCategory;
          let rubric = {
            id: product.get('rubric').get('id'),
            name: product.get('rubric').get('name'),
            count: 1,
            categories: [],
          }

          if (product.get('category').get('id')) {
            category = {
              id: product.get('category').get('id'),
              name: product.get('category').get('name'),
              count: 1,
              subCategories: [],
            }

            if (product.get('subCategory').get('id')) {
              subCategory = {
                id: product.get('subCategory').get('id'),
                name: product.get('subCategory').get('name'),
                count: 1,
              }
              category.subCategories.push(subCategory);
            }

            rubric.categories.push(category);
          }

          const rubricItem = rubrics.filter(e => e.id === rubric.id);
          if (rubricItem.length > 0) {
            rubricItem[0].count++;

            const categoryItem = rubricItem[0].categories.filter(e => e.id === category.id);
            if (categoryItem.length > 0) {
              categoryItem[0].count++;

              if (subCategory) {
                const subCategoryItem = categoryItem[0].subCategories.filter(e => e.id === subCategory.id);
                if (subCategoryItem.length > 0) {
                  subCategoryItem[0].count++;
                } else {
                  categoryItem[0].subCategories.push(subCategory);
                }
              }
            } else {
              rubricItem[0].categories.push(category);
            }
          } else {
            rubrics.push(rubric);
          }
        }

      })
      return rubrics;
    }

    return false;
  }

  @action
  addOrRemove(array, value) {
    const index = array.indexOf(value);

    if (index === -1) {
      array.push(value);
    } else {
      array.splice(index, 1);
    }

    this.products = this.filteredProducts();

    notifyPropertyChange(this, 'products');
    notifyPropertyChange(this, 'filters');
  }

  @action
  changeViewType(viewType) {
    this.viewType = viewType;
    this.viewTypeClass = `products-list--${viewType}`;
  }

  @action
  filteredProducts() {
    if (!this.args.products) return;
    if (!this.filters.rubrics.length && !this.filters.categories.length && !this.filters.subCategories.length) return this.args.products;
    let products = [];
    let skipCat = [];
    let skipRub = [];

    this.args.products.forEach(item => {
      const rubric = item.rubric.get('id');
      const category = item.category.get('id');
      const subCategory = item.subCategory.get('id');

      if (this.filters.subCategories.length && subCategory && this.filters.subCategories.includes(subCategory)) {
        products.push(item);
        skipCat.push(category);
        skipRub.push(rubric);
      }

      if (this.filters.categories.length && !skipCat.includes(category) && this.filters.categories.includes(category)) {
        products.push(item);
        skipRub.push(rubric);
      }

      if (this.filters.rubrics.length && !skipRub.includes(rubric) && this.filters.rubrics.includes(rubric)) {
        products.push(item);
      }

    });
    return products;
  }

}
