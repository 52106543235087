import Model, {
  attr,
  belongsTo,
  hasMany,
} from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class AccountModel extends Model {
  @attr('string') phoneNumber;
  @attr('string') companyName;
  @attr('boolean') status;
  @attr('string') title;
  @attr('string') desc;
  @attr('string') itin;
  @attr('string') code;
  @belongsTo('manager') manager;
  @hasMany('user') users;
  @hasMany('deliveryAddress') deliveryAddresses;
  get getDesc() {
    try {
      return htmlSafe(this.desc)
    }
    catch (e) {
      return htmlSafe(this.desc)
    }
  }
}
