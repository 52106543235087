import Model, {
  attr,
  belongsTo,
} from '@ember-data/model';

export default class CartItemModel extends Model {
  
  @attr('number') quantity;
  @attr() createdAt;
  @attr() updatedAt;
  @attr('number') price;
  @attr('number') total;
  @belongsTo('product') product;
  @belongsTo('cart') cart;
}
