import Model, {
  attr,
} from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class ManagerModel extends Model {
  @attr('string') name;
  @attr('string') phone;
  @attr('string') desciption;
  @attr() image;
  @attr('number') account;
  get getDesc() {
    return htmlSafe(this.desciption.replaceAll('Verdana;', 'Inter, sans-serif;'))    
  }
}
