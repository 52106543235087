import Application from '@ember/application';
import Resolver from './resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
import ENV from 'bumgild-app-client/config/environment';

// import * as Sentry from '@sentry/ember';

// Sentry.init({
//   dsn: 'https://070f23d4cf69460faf0a501aae73502f@sentry.yesit.dev/4',
//   environment: ENV.environment,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


const App = Application.extend({
  modulePrefix: config.modulePrefix,
  podModulePrefix: config.podModulePrefix,
  Resolver,
});

loadInitializers(App, config.modulePrefix);

export default App;