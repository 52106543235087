import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default class FooterComponent extends Component {
  @service store;

  @computed('store')
  get footerBlocks() {
    return this.store.query('text-block', {
      filter: {
        title: [
          'podval1',
          'podval2',
          'podval3',
        ],
      },
      sort: 'title',
      page: {
        number: 1,
        size: 3,
      },
    });
  }
}
