import Component from '@glimmer/component';
import {
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class StockProductListComponent extends Component {
  @service store;
  @service router;

  @computed('router')
  get getBody() {
    return this.store
      .query('text-block', {
        filter: {
          title: 'promotion',
        },
        sort: 'id',
        page: {
          number: 1,
          size: 1,
        },
      });
  }


  @computed('args.{rubric,category,subCategory}')
  get products() {
    // const {
    //   rubric,
    //   category,
    //   subCategory,
    // } = this.args;

    let filters = {
      'filter[is_promotion]': true,
      page: {
        number: 1,
        size: 10,
      },
    };

    // if (subCategory) {
    //   filters = Object.assign(filters, {
    //     'filter[sub_category]': subCategory.id,
    //   });
    // }

    // if (category) {
    //   filters = Object.assign(filters, {
    //     'filter[category]': category.id,
    //   });
    // }

    // if (this.args.rubric) {
    //   filters = Object.assign(filters, {
    //     'filter[rubric]': rubric.id,
    //   });
    // }

    return this.store.query('product', filters, {
      sort: 'name',
    });
  }
}
