import Service from '@ember/service';

export default class UtilService extends Service {
  checkUNP(unp) {
    if (unp.length != 9) return false;

    const arr = [];

    for (let i = 0; i < 9; i++) {
      arr.push(parseInt(unp.substring(i, i + 1)))
    }

    let sum = arr[0] * 29;
    sum = sum + arr[1] * 23;
    sum = sum + arr[2] * 19;
    sum = sum + arr[3] * 17;
    sum = sum + arr[4] * 13;
    sum = sum + arr[5] * 7;
    sum = sum + arr[6] * 5;
    sum = sum + arr[7] * 3;

    const total = sum - (Math.floor(sum / 11) * 11);

    if (total != arr[8]) return false;

    return true;
  }

  truncate(str, n, useWordBoundary) {
    if(!str) return false;

    if (str.length <= n) {
      return str;
    }
    const subString = str.substr(0, n - 1); // the original check
    return (useWordBoundary ?
      subString.substr(0, subString.lastIndexOf(' ')) :
      subString);
  }
}
