import Component from '@glimmer/component';
import {
  action,
  computed,
} from '@ember/object';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';
import ENV from '../../../../../config/environment';

export default class PersonalHystoryRowComponent extends Component {
  @service cart;
  @service router;
  @service store;
  @service currentUser;
  @service intl;

  @tracked showProducts;
  @tracked showMemo;

  apiHost = ENV.API.host;

  @computed('args.order')
  get order() {
    return this.store.findRecord('order', this.args.order.id, {
      include: 'order-items,order-files',
    })
  }

  @computed('order')
  get orderStatus() {
    const status = this.order.get('status');
    return status.toLowerCase() === 'new' ? this.intl.t('statuses.new') : status;
  }

  @computed('order')
  get deliveryInfo() {
    const date = this.order.get('shiped_at');
    const self = this.order.get('self_delivery');

    let deliveryInfo = '';

    if (self && !date) {
      deliveryInfo = 'self'
    }

    if (date && !self) {
      deliveryInfo = 'date'
    }
    return deliveryInfo;
  }

  @action
  toogleProducts() {
    this.showProducts = !this.showProducts;
  }

  @action
  toogleMemo() {
    this.showMemo = !this.showMemo;
  }

  @action
  async copyProducts() {
    if (!this.args.order.orderItems.length) return;

    await this.cart.checkCart();

    let items = [];
    this.args.order.orderItems.forEach(async item => {
      if (!item.get('product').get('content').hidden) {
        items.push(item);
      }
    });

    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        await this.cart.addItem(items[i].get('product').get('content'), items[i].quantity);
      }

      this.router.transitionTo('cart');
    }
  }
}
