import Component from '@glimmer/component';
import {
  inject as service,
} from '@ember/service';

export default class CartTopComponent extends Component {
  @service cart

  constructor() {
    super(...arguments);
    this.cart.getCart();
  }

}
