import Controller from '@ember/controller';
import {
  inject as service,
} from '@ember/service';
import {
  action,
  computed,
} from '@ember/object';
import {
  tracked,
} from '@glimmer/tracking';
import {
  task,
} from 'ember-concurrency-decorators';

export default class RegistrationController extends Controller {
  @service session;
  @service axios;
  @service router;
  @service('fake-fastboot') fastboot;
  @service intl;
  @service store;
  @service util;

  @tracked userData = {
    email: null,
    token: null,
    first_name: null,
    last_name: null,
    organization: null,
    phone: null,
    unp: null,
    password: null,
    repeatPassword: null,
  };

  @tracked submited = false;
  @tracked nextMessage;
  @tracked unpError;
  @tracked agreement = false;

  @computed('router')
  get unpInfo() {
    return this.store.query('text-block', {
      filter: {
        title: 'unn',
      },
      sort: 'id',
      page: {
        number: 1,
        size: 1,
      },
    })
  }


  @computed('router.currentRoute.params.token')
  get checkMail() {
    this.checkEMail();
    return null;
  }

  checkEMail() {
    this.axios
      .get('/confirm-registration/' + this.router.currentRoute.params.token)
      .then(res => {
        if (!this.fastboot.isFastBoot) {
          if (res.data.email == localStorage.getItem('registrationMail')) {
            this.userData.email = res.data.email;
            this.userData.token = this.router.currentRoute.params.token;
            this.checkMail = res.data.email;
          }
        }
      });
  }

  @action
  updatePhone(e) {
    this.set('userData.phone', e);
  }

  @task
  registrationConfirm = function* (event) {
    event.preventDefault();

    this.submited = true;
    this.unpError = false;

    if (!this.agreement) return;

    if (
      !this.userData.email ||
      !this.userData.token ||
      !this.userData.first_name ||
      !this.userData.organization ||
      !this.userData.unp ||
      !this.userData.phone ||
      !this.userData.password ||
      !this.userData.repeatPassword
    )
      return;

    this.set('userData.phone', `375${this.userData.phone}`);

    if (this.unpInfo.lastObject.body == 1 && !this.util.checkUNP(this.userData.unp)) {
      this.unpError = true;
      return;
    }
    if (this.userData.phone.length < 12) return;
    if (this.userData.password.length < 6) return;
    if (this.userData.password != this.userData.repeatPassword) return;

    try {
      yield this.axios
        .post('/confirm-registration/', this.userData)
        .then(res => {
          if (res.error) {
            this.nextMessage = res.error;
          } else {
            this.nextMessage = this.intl.t('registration.success');
            this.session.authenticate(
              'authenticator:oauth2',
              this.userData.email,
              this.userData.password,
            );
          }
        });
    } catch (reason) {
      this.nextMessage = this.intl.t('registration.faild');
    }
  };
}
