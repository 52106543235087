/*
|-----------------------------------------------------------------------------
| Leading (line height)              https://tailwindcss.com/docs/line-height
|-----------------------------------------------------------------------------
|
| Here is where you define your line height values, or as we call
| them in Tailwind, leadings.
|
| Class name: .leading-{size}
| CSS property: line-height
|
*/

export default {
  'none': 1,
  'tight': 1.25,
  'normal': 1.5,
  'loose': 2,
};
