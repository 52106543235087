import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';

export default class ProductDetailsComponent extends Component {
  @service store;
  @service cart;
  @service favorites;
  @service session;
  @service intl;
  @service product;
  @service file;

  @tracked cartCount;
  @tracked countInCart = 0;
  @tracked modal = false;
  @tracked modalImg;

  breakpoints = {
    630: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    868: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
  };

  constructor() {
    super(...arguments);
    this.category = this.args.product.category;

    this.getCountInCart();
    this.file.getNoImages();
  }

  @computed('args.product')
  get getProductData() {
    return this.product.getProductData(this.args.product);
  }

  get priceWtax() {
    return parseFloat(this.args.product.price * 1.2).toFixed(2);
  }

  @computed('category')
  get sameProducts() {
    return this.store.query(
      'product',
      Object.assign({
        'filter[category]': this.category.get('id'),
      }, {
        page: {
          number: 1,
          size: 20,
        },
      }),
    );
  }

  get favorite() {
    const product = this.args.product;

    if (product) {
      return this.favorites.getFavoriteProduct(product);
    }

    return null;
  }

  get favInfo() {
    if (this.session.isAuthenticated) {
      if (this.favorite) {
        return this.intl.t('favorite.userHelpInfo.remove');
      }

      return this.intl.t('favorite.userHelpInfo.add');
    }

    return this.intl.t('favorite.guessHelpInfo');
  }

  get noImageStyle() {
    const style = `background-image: url(${this.file.noImage});`;

    return htmlSafe(style);
  }

  @action
  async addToCart() {
    if (this.cartCount <= 0) return;
    await this.cart.checkCart();
    await this.cart.addItem(this.args.product, this.cartCount);
    this.cartCount = 0;
    this.getCountInCart();
  }

  @action
  minusCartCount() {
    let cartCount = this.cartCount || 0;

    if (cartCount === 1) return;

    this.cartCount--;
  }

  @action
  plusCartCount() {
    if (!this.cartCount) this.cartCount = 0;
    this.cartCount++;
  }

  @action
  async removeFromCart() {
    await this.cart.removeItem(this.args.product);
    this.getCountInCart();
  }

  @action
  async getCountInCart() {
    if (!this.cart.getCartItem(this.args.product)) {
      this.countInCart = 0;
      return;
    }
    this.countInCart = this.cart.getCartItem(this.args.product).quantity;
  }

  @action
  async removeFavorite() {
    await this.favorites.removeFavoriteProduct(this.args.product);
  }

  @action
  async addFavorite() {
    await this.favorites.addFavoriteProduct(this.args.product);
  }

  @action
  async openImage(img) {
    this.modalImg = img;
    this.toggleModal();
  }

  @action
  async toggleModal() {
    this.modal = !this.modal;
  }
}
