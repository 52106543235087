import Service, {
  inject as service,
} from '@ember/service';
import {
  tracked,
} from '@glimmer/tracking';
import { action } from '@ember/object';

export default class OrderService extends Service {
  @service store;
  @service currentUser;
  @service cart;
  @service('fake-fastboot') fastboot;
  @service router;

  @tracked dayOrders = 0;
  @tracked orderData;
  @tracked success;
  @tracked userTodayOrders;
  @tracked ordersLimited;
  @tracked userPermission;

  getContact(contact) {
    if (!this.currentUser.user) return null;

    if (this.currentUser.user.userContacts.length && !contact) return null;

    if (contact != '') {
      if (contact != 'empty') {
        this.currentUser.user.userContacts.forEach(item => {
          if (contact == item.id) {
            contact = item;
          }
        });
      }
    }

    return contact || 'empty';
  }

  getAddress(address) {
    if (!this.currentUser.user) return null;

    if (this.currentUser.user.deliveryAddresses.length > 1 && !address)
      return null;

    if (this.currentUser.user.deliveryAddresses.length == 1) {
      address = this.currentUser.user.deliveryAddresses.firstObject;
    } else {
      if (address != '') {
        this.currentUser.user.deliveryAddresses.forEach(item => {
          if (address == item.id) {
            address = item;
          }
        });
      }
    }

    return address || 'empty';
  }

  async createOrder(
    contact = null,
    address = null,
    comment = null,
    withItems = false,
  ) {
    await this.checkUser();
    await this.checkLimit();
    if (this.userTodayOrders > 10000) {
      this.ordersLimited = true;
      return;
    }
    if (this.userPermission){
      return;
    }

    contact = this.getContact(contact);
    address = this.getAddress(address);

    if (!contact || !address) return;

    const order = this.store.createRecord('order');
    order.memo = comment;
    order.status = 'New';
    order.user = this.currentUser.user;
    order.user_contact = contact != 'empty' ? contact : '';
    order.delivery_address = address != 'empty' ? address : '';
    order.account = this.currentUser.user.account.get('content');

    await order.save().then(savedSelection => {
      this.orderData = savedSelection;
      this.success = true;
    });
    
    if (withItems) await this.addItems();
  }

  async addItems() {
    if (!this.orderData) return false;
    await this.cart.checkCart();

    this.orderData.set('totalPrice', this.cart.totalSum);
    await this.orderData.save();

    this.cart.cartData.cartItems.forEach(async item => {
      const orderItem = this.store.createRecord('order-item')
      orderItem.createdAt = item.createdAt;
      orderItem.updatedAt = item.updatedAt;
      orderItem.quantity = item.quantity;
      orderItem.order = this.orderData;
      orderItem.product = item.product;
      orderItem.price = item.price;      
      orderItem.amount = (item.price * item.quantity).toFixed(2);

      await orderItem.save().then(async () => {
        await this.cart.deleteItem(item);
      });
    });

    return true;
  }

  checkLimit() {
    this.userTodayOrders = 0;
    let today = new Date();
    today = today.getFullYear().toString() + today.getMonth().toString() + today.getDate().toString();

    this.currentUser.user.orders.forEach(order => {

      let orderDate = new Date(order.createdAt);
      orderDate = orderDate.getFullYear().toString() + orderDate.getMonth().toString() + orderDate.getDate().toString();
      if (today == orderDate) {
        this.userTodayOrders++;
      }
    });
  }

  checkUser() {
    if(this.currentUser.user.user_role) {
      this.userPermission = true;
    }
  }

  @action
  goContacts() {
    this.userPermission = false;
    this.router.transitionTo('contact');
  }

  @action
  closeOrderMessage() {
    this.success = false;
    if (!this.fastboot.isFastBoot) {
      localStorage.setItem('personalTab', 'hystory');
    }
    this.router.transitionTo('personal');
  }

}
