import Model, {
  attr,
  belongsTo,
} from '@ember-data/model';

export default class OrderItemModel extends Model {

  @attr() createdAt;
  @attr() updatedAt;
  @attr('number') quantity;
  @attr('string') price;
  @attr('string') amount;
  @belongsTo('product') product;
  @belongsTo('order') order;
}
