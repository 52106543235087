import Controller from '@ember/controller';
import {
  computed,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class ApplicationController extends Controller {
  @service router;

  @computed('router.currentRouteName')
  get routeClass() {
    const routeName = this.router.currentRouteName;
    const routeSegs = routeName.split('.');
    const classes = [];

    for (let i = 0; i < routeSegs.length; i++) {
      classes.push(['route', ...routeSegs.slice(0, i + 1)].join('--'));
    }

    return classes.join(' ');
  }
}
