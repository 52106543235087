import JSONAPISerializer from '@ember-data/serializer/json-api';
import { underscore } from '@ember/string';

export default class UserSerializer extends JSONAPISerializer {
  keyForAttribute(key) {
    return underscore(key);
  }

  keyForRelationship(key) {
    return underscore(key);
  }
}
