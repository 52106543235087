import JSONAPISerializer from '@ember-data/serializer/json-api';
import { spritUnnecessaryTags } from '../utils';
import MainSerializer from './main';

export default class TextBlockSerializer extends MainSerializer {
  normalizeResponse(store, primaryModelClass, payload) {
      for (const item of payload.data) {
        item.attributes.body = spritUnnecessaryTags(item.attributes.body);
      }

    return super.normalizeResponse(...arguments);
  }
}
