import Controller from '@ember/controller';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CategoryRubricIndexController extends Controller {
  @tracked seeDesc;
  @service intl;
  @service('fake-fastboot') fastboot;

  @computed('model')
  get breadCrumbs() {
    return [{
        label: this.intl.t('common.home'),
        path: 'home',
      },
      {
        label: this.intl.t('catalog.title'),
        path: 'catalog',
      },
      {
        label: this.model.rubric.firstObject.name,
        isCurrent: true,
        linkable: false,
      },
    ];
  }

  @computed('model.rubric.firstObject.desc')
  get descHeight() {
      const newElement = document.createElement('div');
      newElement.innerHTML = this.model.rubric.firstObject.getDesc;

      document.body.appendChild(newElement);

      const descHeight = newElement.getBoundingClientRect().height;

      document.body.removeChild(newElement);

      return descHeight;
  }

  get descStyle() {
    if (!this.showDescToggler) {
      return htmlSafe('');
    }

    const style = this.seeDesc ? '' : 'height: 38px; overflow:hidden';

    return htmlSafe(style);
  }

  get showDescToggler() {
    return this.descHeight > 30;
  }

  @action
  toogleDesc(event) {
    event.preventDefault();

    this.seeDesc = !this.seeDesc;
  }
}
