import Route from '@ember/routing/route';
import {
  inject as service,
} from '@ember/service';
import {
  hash,
} from 'rsvp';


export default class FileDownloadGroupRoute extends Route {
  constructor() {
    super(...arguments);
  }

  @service intl;
  @service util;

  queryParams = {
    // genaral
    page: {
      refreshModel: true,
    },
    size: {
      refreshModel: true,
    },
  };

  afterModel(model) {
    this.setHeadTags(model);
  }

  setHeadTags(model) {
    let headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.util.truncate(model.group.desc, 170, true),
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: model.group.keywords,
        },
      },
    ];

    this.set('headTags', headTags);
  }

  model({
    page,
    size,
    group_id,
  }) {
    return hash({
      group: this.store.findRecord('download-group', group_id),
      files: this.store.query('download', {
        filter: {
          download_group: group_id,
        },
        sort: 'id',
        page: {
          number: page,
          size,
        },
      }),
    });
  }
}
