import Service, {
  inject as service,
} from '@ember/service';

export default class ProductService extends Service {
  @service session;

  getProductData(product) {
    if (!product) return;

    let status = 'is_reserved';
    let price = 0;

    if (product.isStock) {
      status = 'is_stock';
      if (product.isReserved) {
        status = 'is_reserved';
        if (product.onOrder) {
          status = '';
        }
      }
    } else if (!product.isStock && !product.isReserved && product.onOrder) {
      status = '';
    }

    if (status != 'is_reserved' && status != '') {
      price = product.price;
    }

    return {
      status,
      price,
    };
  }
}
