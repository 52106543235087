import Route from '@ember/routing/route';
import {
  action,
} from '@ember/object';
import {
  inject as service,
} from '@ember/service';

export default class HomeRoute extends Route {
  @service intl;

  constructor() {
    super(...arguments);
  }

  afterModel() {
    this.setHeadTags();

    // this.store.findAll('rubric');
    // this.store.findAll('category');
    // this.store.findAll('sub-category');
  }

  setHeadTags() {
    let headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: this.intl.t('common.desc'),
        },
      },
      {
        type: 'meta',
        tagId: 'meta-keywords-tag',
        attrs: {
          name: 'keywords',
          content: this.intl.t('common.keywords'),
        },
      },
    ];

    this.set('headTags', headTags);
  }

  model() {
    return this.store.query('text-block', {
      filter: {
        title: [
          'main1',
          'main2',
          'main3',
          'main4',
          'main5',
          'main6',
          'main7',
          'main8',
          'main9',
          'main10',
        ],
      },
      sort: 'title',
      page: {
        number: 1,
        size: 10,
      },
    });
  }

  @action
  didTransition() {
    // eslint-disable-next-line
    this._super(...arguments);
    return true;
  }
}
