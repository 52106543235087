import Component from '@glimmer/component';
import {
  tracked,
} from '@glimmer/tracking';
import {
  inject as service,
} from '@ember/service';
import {
  action,
} from '@ember/object';
import {
  task,
} from 'ember-concurrency-decorators';

export default class PersonalUsersComponent extends Component {
  @service store;
  @service router;
  @service currentUser;
  @tracked first_name;
  @tracked phone;

  @action
  updatePhone(e) {
    this.phone = e;
  }

  @task
  addUserContact = function* (event) {
    event.preventDefault();

    if (!this.first_name || !this.phone) return;

    if (this.phone.length < 9) return;

    const newUserContact = this.store.createRecord('user-contact', {
      firstName: this.first_name,
      phone1: '375' + this.phone,
      user: this.currentUser.user,
    });

    yield newUserContact
      .save()
      .then(() => {
        this.first_name = '';
        this.phone = '';
      });
  };
}
